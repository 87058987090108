import { TitanCampaignNote } from '@cognitiv/galaxy-api';

export const titan_campaign_note_default: TitanCampaignNote = {
  campaign_note_id: 0,
  campaign_id: 0,
  campaign_name: '',
  created_date_utc: '',
  created_date_formatted: '',
  last_modified_date_utc: '',
  last_modified_date_formatted: '',
  campaign_note_category_name: '',
  campaign_note_category_id: 0,
  state_id: 0,
  state_name: '',
  note: '',
  impact: '',
  last_modified_by_display_name: '',
  last_modified_by_user_id: 0,
  created_by_display_name: '',
  action_date: '',
  action_date_formatted: '',
  uuid: '',
};
