import { TitanNotificationDelivery } from '@cognitiv/galaxy-api';
import { TITAN_DELIVERY_TYPE_NAME } from 'products/titan/constants';
import { TITAN_DELIVERY_TYPE_ID } from 'products/titan/enums';

export const default_delivery_destination: TitanNotificationDelivery = {
  delivery_type_id: 0,
  delivery_type_name: '',
  uuid: '',
  delivery_values: [],
};

export const default_slack_delivery_destination: TitanNotificationDelivery = {
  ...default_delivery_destination,
  delivery_type_id: TITAN_DELIVERY_TYPE_ID.SLACK,
  delivery_type_name: TITAN_DELIVERY_TYPE_NAME[TITAN_DELIVERY_TYPE_ID.SLACK],
};

export const default_email_delivery_destination: TitanNotificationDelivery = {
  ...default_delivery_destination,
  delivery_type_id: TITAN_DELIVERY_TYPE_ID.EMAIL,
  delivery_type_name: TITAN_DELIVERY_TYPE_NAME[TITAN_DELIVERY_TYPE_ID.EMAIL],
};

export const titan_notification_slice_default = {
  slack: [],
  email: [],
};
