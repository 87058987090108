import { TitanCreative } from '@cognitiv/galaxy-api';

export const titan_creative_default: TitanCreative = {
  creative_id: 0,
  creative_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  creative_state_id: 0,
  creative_state_name: '',
  creative_type_id: 0,
  creative_type_name: '',
  banner_size_id: null,
  banner_size_name: '',
  banner_height: 0,
  banner_width: 0,
  banner_tag_html: '',
  ad_server_id: 0,
  ad_server_name: '',
  banner_click_url: '',
  video_skippable: false,
  is_psa: false,
  video_duration_seconds: 0,
  video_duration_seconds_formatted: '',
  video_vast_ad_title: '',
  video_vast_url: '',
  ad_server_campaign_identifier: '',
  ad_server_identifier: '',
  revision_id: 0,
  creative_message_id: null,
  creative_message_name: '',
  uuid: '',
  addons: [],
  creative_test_state_id: 0,
  creative_test_state_name: '',
  cpm_usd: 0,
  cpm_usd_formatted: '',
  domain_url: '',
};
