import { TitanDeliverySource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanDeliverySource[] = [];

export const titanDeliverySourcesListSlice = createSlice({
  name: SLICE.TITAN_DELIVERY_SOURCES_LIST,
  initialState,
  reducers: {
    clearTitanDeliverySourcesList: () => {
      return initialState;
    },
    setTitanDeliverySourcesList: (_, action: PayloadAction<TitanDeliverySource[]>) => action.payload,
  },
});

export const { clearTitanDeliverySourcesList, setTitanDeliverySourcesList } = titanDeliverySourcesListSlice.actions;

export default titanDeliverySourcesListSlice.reducer;
