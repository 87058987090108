import React, { useCallback } from 'react';
import { Button, Form, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'modals/utility/Modal.module.scss';

export const Confirm = () => {
  const dispatch = useAppDispatch();

  const { is_open, title, message, label, onConfirm } = useAppSelector((state) => selectModal(state, 'confirm'));

  const onClose = useCallback(() => {
    dispatch(updateModal({ confirm: { is_open: false } }));
  }, [dispatch]);

  const onConfirmClick = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <Modal title={title} identifier="confirm" is_open={is_open} width={440} onClose={onClose}>
      <Form>
        <div className={cn.body}>
          <p className={cn.message}>{message}</p>
        </div>
        <div className={cn.footer}>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirmClick}>{label}</Button>
        </div>
      </Form>
    </Modal>
  );
};
