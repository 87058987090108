import { TitanCampaignNoteCategory } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignNoteCategory[] = [];

export const titanCampaignNoteCategoriesListSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_NOTE_CATEGORIES_LIST,
  initialState,
  reducers: {
    clearTitanCampaignNoteCategoriesList: () => {
      return initialState;
    },
    setTitanCampaignNoteCategoriesList: (_, action: PayloadAction<TitanCampaignNoteCategory[]>) => action.payload,
  },
});

export const { clearTitanCampaignNoteCategoriesList, setTitanCampaignNoteCategoriesList } = titanCampaignNoteCategoriesListSlice.actions;

export default titanCampaignNoteCategoriesListSlice.reducer;
