import { LibraDomainList } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraDomainList[] = [];

export const libraDomainListsSlice = createSlice({
  name: SLICE.LIBRA_DOMAIN_LISTS,
  initialState,
  reducers: {
    clearLibraDomainLists: () => [],
    setLibraDomainLists: (_, action: PayloadAction<LibraDomainList[]>) => action.payload,
  },
});

export const { clearLibraDomainLists, setLibraDomainLists } = libraDomainListsSlice.actions;

export default libraDomainListsSlice.reducer;
