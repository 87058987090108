import { VirgoModals } from 'products/virgo/modals/types';

export const virgo_modals: VirgoModals = {
  manage_virgo_campaign: { is_open: false },
  create_virgo_deals: { is_open: false },
  manage_virgo_audience: { is_open: false },
  view_virgo_audiences: { is_open: false },
  manage_virgo_deal_note: { is_open: false },
  update_virgo_deal: { is_open: false },
};
