import { TitanCreative } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_creative_default } from 'products/titan/operators/creative/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCreative = { ...titan_creative_default };

export const titanCreativeSlice = createSlice({
  name: SLICE.TITAN_CREATIVE,
  initialState,
  reducers: {
    clearTitanCreative: () => initialState,
    setTitanCreative: (_, action: PayloadAction<TitanCreative>) => action.payload,
  },
});

export const { clearTitanCreative, setTitanCreative } = titanCreativeSlice.actions;

export default titanCreativeSlice.reducer;
