import { LibraDomainListConfiguration } from 'products/libra/modals/manage-libra-domain-list/types';

export const configuration_default: LibraDomainListConfiguration = {
  files: [],
  presigned_url: '',
  count: 0,
  polling: false,
  message: 'Contact Cognitiv',
  upload_error_message: '',
};
