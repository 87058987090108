import { TitanAdvertiser } from '@cognitiv/galaxy-api';

export const titan_advertiser_default: TitanAdvertiser = {
  advertiser_name: '',
  advertiser_id: 0,
  partner_name: '',
  partner_id: 0,
  iab_category_name: '',
  iab_category_id: null,
  domain_url: '',
  state_id: 0,
  state_name: '',
  created_by: '',
  created_date: '',
  created_date_formatted: '',
  uuid: '',
  created_by_user_id: 0,
};
