import { createMap } from '@cognitiv/cicada-ui';
import {
  HYPERION_CASCADE_SORTING_ACTION,
  HYPERION_COLUMN_ACTION,
  HYPERION_CONDITION_ACTION,
  HYPERION_CONDITIONS_ACTION,
  HYPERION_FILTER_ACTION,
  HYPERION_INTERVAL_TYPE,
  HYPERION_OUTPUT_TYPE,
  HYPERION_REPORT_SAVE_OPTION_ID,
  HYPERION_REPORT_SCHEDULE_VIEW,
  HYPERION_SETTING_TYPE,
  HYPERION_SETTINGS_ACTION,
  HYPERION_TABLE_ACTION,
  HYPERION_TIMESTAMP_ACTION,
  HYPERION_VIEW,
  REPORT_SCHEDULE_ACTION,
} from 'products/hyperion/pages/custom-report/enums';

export const HYPERION_TIMESTAMP_ACTION_MAP = createMap(HYPERION_TIMESTAMP_ACTION);

export const HYPERION_COLUMN_ACTION_MAP = createMap(HYPERION_COLUMN_ACTION);

export const HYPERION_FILTER_ACTION_MAP = createMap(HYPERION_FILTER_ACTION);

export const HYPERION_SETTINGS_ACTION_MAP = createMap(HYPERION_SETTINGS_ACTION);

export const HYPERION_TABLE_ACTION_MAP = createMap(HYPERION_TABLE_ACTION);

export const HYPERION_VIEW_MAP = createMap(HYPERION_VIEW);

export const HYPERION_CONDITIONS_ACTION_MAP = createMap(HYPERION_CONDITIONS_ACTION);

export const HYPERION_CONDITION_ACTION_MAP = createMap(HYPERION_CONDITION_ACTION);

export const HYPERION_CASCADE_SORTING_ACTION_MAP = createMap(HYPERION_CASCADE_SORTING_ACTION);

export const REPORT_SCHEDULE_ACTION_MAP = createMap(REPORT_SCHEDULE_ACTION);

export const HYPERION_DATA_TYPE = {
  STRING: 1,
  INT: 2,
  BOOL: 3,
  DOUBLE: 4,
  TIMESTAMP: 5,
  NUMERIC: 6,
  DATE: 7,
};

export const HYPERION_FIELD_TYPE = {
  MEASURES: 1,
  DIMENSIONS: 2,
  KPIS: 3,
};

export const HYPERION_REPORT_TYPE_CONFIRMATION = 'confirm_hyperion_report_type';

export const HYPERION_OUTPUT_TYPE_NAME: Record<HYPERION_OUTPUT_TYPE, string> = {
  [HYPERION_OUTPUT_TYPE.CSV]: 'CSV',
  [HYPERION_OUTPUT_TYPE.EXCEL]: 'Excel',
  [HYPERION_OUTPUT_TYPE.PDF]: 'PDF',
};

export const HYPERION_INTERVAL_TYPE_NAME: Record<HYPERION_INTERVAL_TYPE, string> = {
  [HYPERION_INTERVAL_TYPE.DAILY]: 'Daily',
  [HYPERION_INTERVAL_TYPE.WEEKLY]: 'Weekly',
  [HYPERION_INTERVAL_TYPE.MONTHLY]: 'Monthly',
};

export interface HyperionDropdownOption {
  option_id: number;
  option_label: string;
  uuid: string;
}

export const hyperion_report_settings_options: HyperionDropdownOption[] = [
  {
    option_label: 'Reset Filter',
    option_id: HYPERION_SETTING_TYPE.RESET_FILTERS,
    uuid: '1',
  },
  {
    option_label: 'Clear Columns',
    option_id: HYPERION_SETTING_TYPE.CLEAR_COLUMNS,
    uuid: '2',
  },
  {
    option_label: 'Striped',
    option_id: HYPERION_SETTING_TYPE.STRIPED,
    uuid: '3',
  },
  {
    option_label: 'Wrap Text',
    option_id: HYPERION_SETTING_TYPE.WRAP_TEXT,
    uuid: '4',
  },
  {
    option_label: 'Cascade Sort',
    option_id: HYPERION_SETTING_TYPE.CASCADE_SORT,
    uuid: '7',
  },
];

export const hyperion_report_schedule_options: HyperionDropdownOption[] = [
  {
    option_label: 'Daily Slack Report',
    option_id: HYPERION_REPORT_SCHEDULE_VIEW.SLACK,
    uuid: '1',
  },
  {
    option_label: 'Daily Email Report',
    option_id: HYPERION_REPORT_SCHEDULE_VIEW.EMAIL,
    uuid: '2',
  },
  {
    option_label: 'Custom Report',
    option_id: HYPERION_REPORT_SCHEDULE_VIEW.CUSTOM,
    uuid: '3',
  },
  {
    option_label: 'Edit Report Schedules',
    option_id: HYPERION_REPORT_SCHEDULE_VIEW.ALL_SCHEDULES,
    uuid: '4',
  },
];

export const hyperion_report_save_options: HyperionDropdownOption[] = [
  {
    option_label: 'Save',
    option_id: HYPERION_REPORT_SAVE_OPTION_ID.SAVE,
    uuid: '1',
  },
  {
    option_label: 'Save and Rename',
    option_id: HYPERION_REPORT_SAVE_OPTION_ID.SAVE_RENAME,
    uuid: '2',
  },
  {
    option_label: 'Save As Copy',
    option_id: HYPERION_REPORT_SAVE_OPTION_ID.SAVE_AS,
    uuid: '3',
  },
];
