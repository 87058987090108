import React from 'react';
import { Form } from '@cognitiv/cassiopeia-ui';

import { SmallSpinner } from 'components/layout/loading/Loading';

import cn from 'products/libra/modals/manage-libra-domain-list/ManageLibraDomainList.module.scss';

export const DomainListProcessing = ({ message }: { message: string }) => {
  return (
    <Form>
      <div className={cn.processing}>
        <SmallSpinner />
        <div>
          <p>{message}</p>
        </div>
      </div>
    </Form>
  );
};
