import { HyperionImportHealthStatusHistory } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_import_status_history_default } from 'products/hyperion/operators/import-global-health-status-history/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionImportHealthStatusHistory = { ...hyperion_import_status_history_default };

export const hyperionImportHealthStatusHistorySlice = createSlice({
  name: SLICE.HYPERION_IMPORT_HEALTH_STATUS_HISTORY,
  initialState,
  reducers: {
    clearHyperionImportHealthStatusHistory: () => initialState,
    setHyperionImportHealthStatusHistory: (_, action: PayloadAction<HyperionImportHealthStatusHistory>) => action.payload,
  },
});

export const { clearHyperionImportHealthStatusHistory, setHyperionImportHealthStatusHistory } = hyperionImportHealthStatusHistorySlice.actions;

export default hyperionImportHealthStatusHistorySlice.reducer;
