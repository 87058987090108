import { HyperionImportConfig } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_import_config_default } from 'products/hyperion/operators/import-config/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionImportConfig = { ...hyperion_import_config_default };

export const hyperionImportConfigSlice = createSlice({
  name: SLICE.HYPERION_IMPORT_CONFIG,
  initialState,
  reducers: {
    clearHyperionImportConfig: () => initialState,
    setHyperionImportConfig: (_, action: PayloadAction<HyperionImportConfig>) => action.payload,
  },
});

export const { clearHyperionImportConfig, setHyperionImportConfig } = hyperionImportConfigSlice.actions;

export default hyperionImportConfigSlice.reducer;
