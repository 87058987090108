import { TitanSalesforceFlight } from '@cognitiv/galaxy-api';

export const titan_salesforce_flight_default: TitanSalesforceFlight = {
  flight_id: 0,
  campaign_id: 0,
  campaign_name: '',
  partner_id: 0,
  partner_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  product_type_id: 0,
  product_type_name: '',
  kpi_type_id: 0,
  kpi_type_name: '',
  opportunity_size_usd: 0,
  salesforce_opportunity_identifier: '',
  start_date: '',
  finish_date: '',
  client_manager: {
    person_id: 0,
    display_name: '',
    user_id: 0,
    sales_region_id: 0,
    sales_region_name: '',
    revision_id: 0,
  },
  sales_lead: {
    person_id: 0,
    display_name: '',
    user_id: 0,
    sales_region_id: 0,
    sales_region_name: '',
    revision_id: 0,
  },
  flight_dates_formatted: '',
  opportunity_size_usd_formatted: '',
  uuid: '',
};
