import { HyperionIntervalType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionIntervalType[] = [];

export const hyperionIntervalTypesListSlice = createSlice({
  name: SLICE.HYPERION_INTERVAL_TYPES_LIST,
  initialState,
  reducers: {
    clearHyperionIntervalTypesList: () => {
      return initialState;
    },
    setHyperionIntervalTypesList: (_, action: PayloadAction<HyperionIntervalType[]>) => action.payload,
  },
});

export const { clearHyperionIntervalTypesList, setHyperionIntervalTypesList } = hyperionIntervalTypesListSlice.actions;

export default hyperionIntervalTypesListSlice.reducer;
