import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TitanPage, useClientContext } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { modals_search_initial_state } from 'modals/search/context/defaults';
import { GlobalSearchContextProps } from 'modals/search/context/types';
import { useKeyDown } from 'modals/search/hooks/useKeyDown';
import { TITAN_PAGE_CATEGORY } from 'products/titan/enums';
import { selectTitanPages } from 'products/titan/operators/pages/selectors';
import { clearTitanPages, setTitanPages } from 'products/titan/operators/pages/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Context = createContext<GlobalSearchContextProps>({ ...modals_search_initial_state });

export const SearchCommandContext: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pages = useAppSelector(selectTitanPages);

  const { is_open } = useAppSelector((state) => selectModal(state, 'search'));

  const [search, setSearch] = useState('');

  const { Titan, handleError } = useClientContext();

  useEffect(() => {
    if (!is_open) return;

    const setupPage = async () => {
      try {
        const titan_pages = await Titan.getTitanPages({ filter: search });
        dispatch(setTitanPages(titan_pages));
      } catch (err) {
        handleError(err);
      }
    };
    setupPage();
  }, [Titan, handleError, dispatch, is_open, search]);

  const onClose = useCallback(() => {
    setSearch('');
    dispatch(clearTitanPages());
    dispatch(updateModal({ search: { is_open: false } }));
  }, [dispatch]);

  const onClick = useCallback(
    (page: TitanPage) => {
      onClose();
      navigate(page.to);
    },
    [navigate, onClose],
  );

  const recently_viewed_pages = useMemo(() => pages.filter((m) => m.page_category_id === TITAN_PAGE_CATEGORY.RECENTLY), [pages]);
  const navigation_pages = useMemo(() => pages.filter((m) => m.page_category_id === TITAN_PAGE_CATEGORY.NAVIGATION), [pages]);

  const options = useMemo(() => [...recently_viewed_pages, ...navigation_pages], [recently_viewed_pages, navigation_pages]);

  const [selected, hovering, onKeyDown, setIndex, setHovering] = useKeyDown({ options, onClick, search });

  const value = useMemo(
    () => ({
      search,
      selected,
      hovering,
      recently_viewed_pages,
      navigation_pages,
      options,
      onKeyDown,
      setIndex,
      setHovering,
      setSearch,
      onClose,
      onClick,
    }),
    [hovering, recently_viewed_pages, navigation_pages, onClick, onClose, onKeyDown, options, search, selected, setHovering, setIndex],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSearchContext = () => useContext(Context);
