import hyperionCsCampaignPerformanceTrackerSlice from 'products/hyperion/operators/cs-campaign-performance/slices';
import hyperionDsCampaignPerformanceTrackerSlice from 'products/hyperion/operators/ds-campaign-performance/slices';
import hyperionEventSourcePerformanceSummarySlice from 'products/hyperion/operators/event-source-performance-summary/slices';
import hyperionFilterTimestampsSlice from 'products/hyperion/operators/filter-timestamps/slices';
import hyperionImportConfigSlice from 'products/hyperion/operators/import-config/slices';
import hyperionImportConfigUnjoinedRowsSlice from 'products/hyperion/operators/import-config-unjoined-rows/slices';
import hyperionImportConfigsSlice from 'products/hyperion/operators/import-configs/slices';
import hyperionImportGlobalHealthStatusHistorySlice from 'products/hyperion/operators/import-global-health-status-history/slices';
import hyperionImportHealthStatusHistorySlice from 'products/hyperion/operators/import-health-status-history/slices';
import hyperionInboundReportsSlice from 'products/hyperion/operators/inbound-reports/slices';
import hyperionIntervalTypesListSlice from 'products/hyperion/operators/interval-types-list/slices';
import hyperionOperatorTypesSlice from 'products/hyperion/operators/operator-types/slices';
import hyperionOutputTypesListSlice from 'products/hyperion/operators/output-types-list/slices';
import hyperionReportCascadeSortingsSlice from 'products/hyperion/operators/report-cascade-sortings/slices';
import hyperionReportScheduleSlice from 'products/hyperion/operators/report-schedule/slices';
import hyperionReportSchedulesSlice from 'products/hyperion/operators/report-schedules/slices';
import hyperionReportTypesSlice from 'products/hyperion/operators/report-types/slices';
import hyperionReportsSlice from 'products/hyperion/operators/reports/slices';

export const hyperion = {
  hyperion_report_types: hyperionReportTypesSlice,
  hyperion_reports: hyperionReportsSlice,
  hyperion_operator_types: hyperionOperatorTypesSlice,
  hyperion_filter_timestamps: hyperionFilterTimestampsSlice,
  hyperion_report_cascade_sortings: hyperionReportCascadeSortingsSlice,
  hyperion_inbound_reports: hyperionInboundReportsSlice,
  hyperion_import_config: hyperionImportConfigSlice,
  hyperion_import_configs: hyperionImportConfigsSlice,
  hyperion_import_global_health_status_history: hyperionImportGlobalHealthStatusHistorySlice,
  hyperion_import_health_status_history: hyperionImportHealthStatusHistorySlice,
  hyperion_cs_campaign_performance_tracker: hyperionCsCampaignPerformanceTrackerSlice,
  hyperion_ds_campaign_performance_tracker: hyperionDsCampaignPerformanceTrackerSlice,
  hyperion_event_source_performance_summary: hyperionEventSourcePerformanceSummarySlice,
  hyperion_import_config_unjoined_rows: hyperionImportConfigUnjoinedRowsSlice,
  hyperion_report_schedule: hyperionReportScheduleSlice,
  hyperion_report_schedules: hyperionReportSchedulesSlice,
  hyperion_interval_types_list: hyperionIntervalTypesListSlice,
  hyperion_output_types_list: hyperionOutputTypesListSlice,
};
