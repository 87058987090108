import { TitanEventSourceDataProviderS3Object } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourceDataProviderS3Object[] = [];

export const titanEventSourceDataProviderS3ObjectsListSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE_DATA_PROVIDER_S3_OBJECTS_LIST,
  initialState,
  reducers: {
    clearTitanEventSourceDataProviderS3ObjectsList: () => {
      return initialState;
    },
    setTitanEventSourceDataProviderS3ObjectsList: (_, action: PayloadAction<TitanEventSourceDataProviderS3Object[]>) => action.payload,
  },
});

export const { clearTitanEventSourceDataProviderS3ObjectsList, setTitanEventSourceDataProviderS3ObjectsList } =
  titanEventSourceDataProviderS3ObjectsListSlice.actions;

export default titanEventSourceDataProviderS3ObjectsListSlice.reducer;
