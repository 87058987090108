export enum SLICE {
  HYPERION_REPORT_TYPES = 'hyperion-report-types',
  HYPERION_REPORTS = 'hyperion-reports',
  HYPERION_OPERATOR_TYPES = 'hyperion-operator-types',
  HYPERION_FILTER_TIMESTAMPS = 'hyperion-filter-timestamps',
  HYPERION_REPORT_CASCADE_SORTINGS = 'hyperion-report-cascade-sortings',
  HYPERION_INBOUND_REPORTS = 'hyperion-inbound-reports',
  HYPERION_IMPORT_CONFIG = 'hyperion-import-config',
  HYPERION_IMPORT_CONFIGS = 'hyperion-import-configs',
  HYPERION_IMPORT_GLOBAL_HEALTH_STATUS_HISTORY = 'hyperion-import-global-health-status-history',
  HYPERION_IMPORT_HEALTH_STATUS_HISTORY = 'hyperion-import-health-status-history',
  HYPERION_CS_CAMPAIGN_PERFORMANCE_TRACKER = 'hyperion-cs-campaign-performance-tracker',
  HYPERION_DS_CAMPAIGN_PERFORMANCE_TRACKER = 'hyperion-ds-campaign-performance-tracker',
  HYPERION_EVENT_SOURCE_PERFORMANCE_SUMMARY = 'hyperion-event-source-performance-summary',
  HYPERION_IMPORT_CONFIG_UNJOINED_ROWS = 'hyperion-import-config-unjoined-rows',
  HYPERION_REPORT_SCHEDULE = 'hyperion-report-schedule',
  HYPERION_REPORT_SCHEDULES = 'hyperion-report-schedules',
  HYPERION_INTERVAL_TYPES_LIST = 'hyperion-interval-types-list',
  HYPERION_OUTPUT_TYPES_LIST = 'hyperion-output-types-list',
}
