import { HyperionImportConfig, HyperionInboundReportConfigSuggestion } from '@cognitiv/galaxy-api';
import { hyperion_inbound_report_default } from 'products/hyperion/operators/inbound-report/defaults';

export const hyperion_import_config_default: HyperionImportConfig = {
  import_config_id: 0,
  import_config_name: '',
  import_config_name_formatted: '',
  importer_id: 0,
  importer_name: '',
  advertiser_id: null,
  advertiser_name: '',
  campaign_id: null,
  campaign_name: '',
  inventory_source_id: null,
  inventory_source_name: '',
  email_sender: '',
  email_subject: '',
  file_name: '',
  mapping_json: { mappings: [] },
  filter_expression: '',
  start_date: '',
  finish_date: '',
  is_archived: false,
  created_by: '',
  created_date: '',
  start_date_formatted: '',
  finish_date_formatted: '',
  created_date_formatted: '',
  uuid: '',
  last_import_state_date: '',
  last_import_state_date_formatted: '',
  last_import_state_id: 0,
  last_import_state_name: '',
  last_import_state_name_formatted: '',
  is_active: true,
  import_config_health_state_id: 0,
  import_config_health_state_name: '',
  import_config_health_state_name_formatted: '',
  import_config_health_state_note: [],
  expected_import_interval_hours: 0,
  next_estimated_import_date: '',
};

export const hyperion_inbound_report_config_suggestion_default: HyperionInboundReportConfigSuggestion = {
  inbound_report: { ...hyperion_inbound_report_default },
  import_columns: [],
  preview_data: [],
  schema_json: { columns: [] },
  mapping_json: { mappings: [] },
  uuid: '',
  rows_imported: 0,
  rows_imported_formatted: '',
  rows_processed: 0,
  rows_processed_formatted: '',
};
