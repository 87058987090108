export enum HYPERION_TIMESTAMP_ACTION {
  UPDATE = 'Update',
}

export enum HYPERION_COLUMN_ACTION {
  INSERT = 'Insert',
  REMOVE = 'Remove',
  UPDATE = 'Update',
  UPDATE_CONDITIONS = 'Update Conditions',
}

export enum HYPERION_FILTER_ACTION {
  ACTIVE = 'Active',
}

export enum HYPERION_SETTINGS_ACTION {
  CLEAR_COLUMNS = 'Clear Columns',
  CLEAR_FILTERS = 'Clear Filters',
  CLEAR_ALL = 'Clear All',
  CLEAR_SORTING = 'Clear Sorting',
}

export enum HYPERION_TABLE_ACTION {
  UPDATE_EXTENDED_COLUMN = 'Update Extended Column',
  UPDATE_EXTENDED_FORMAT = 'Update Extended Format',
  UPDATE_EXTENDED_PRECISION = 'Update Extended Precision',
  UPDATE_DIRECTION = 'Update Direction',
  UPDATE_SORT_ORDER = 'Update Sort Order',
}

export enum HYPERION_VIEW {
  COLUMNS = 'Columns',
  FILTERS = 'Filters',
  FILTER = 'Filter',
  COLUMN_CONDITIONS = 'Column Conditions',
  COLUMN_CONDITION = 'Column Condition',
  CASCADE_SORTING = 'Cascade Sorting',
  CASCADE_SORT = 'Cascade Sort',
}

export enum HYPERION_CONDITIONS_ACTION {
  INSERT = 'Insert',
  UPDATE = 'Update',
  REMOVE = 'Remove',
}

export enum HYPERION_CONDITION_ACTION {
  CREATE = 'Create',
  UPDATE = 'Update',
  EDIT = 'Edit',
}

export enum HYPERION_CASCADE_SORTING_ACTION {
  UPDATE = 'Update',
  REMOVE = 'Remove',
}

export enum REPORT_SCHEDULE_ACTION {
  INSERT = 'Insert',
  ARCHIVE = 'Archive',
}

export enum HYPERION_SETTING_TYPE {
  RESET_FILTERS = 1,
  CLEAR_COLUMNS = 2,
  STRIPED = 3,
  WRAP_TEXT = 4,
  CASCADE_SORT = 11,
}

export enum HYPERION_REPORT_SCHEDULE_VIEW {
  ALL_SCHEDULES = 1,
  CUSTOM = 2,
  SLACK = 3,
  EMAIL = 4,
}

export enum HYPERION_OUTPUT_TYPE {
  CSV = 1,
  EXCEL = 2,
  PDF = 3,
}

export enum HYPERION_INTERVAL_TYPE {
  DAILY = 2,
  WEEKLY = 3,
  MONTHLY = 4,
}

export enum HYPERION_REPORT_SAVE_OPTION_ID {
  SAVE = 1,
  SAVE_RENAME = 2,
  SAVE_AS = 3,
}
