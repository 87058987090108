import { TitanCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_campaign_default } from 'products/titan/operators/campaign/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaign = { ...titan_campaign_default };

export const titanCampaignSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN,
  initialState,
  reducers: {
    clearTitanCampaign: () => initialState,
    setTitanCampaign: (_, action: PayloadAction<TitanCampaign>) => action.payload,
  },
});

export const { clearTitanCampaign, setTitanCampaign } = titanCampaignSlice.actions;

export default titanCampaignSlice.reducer;
