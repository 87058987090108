import { HyperionImportConfig } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionImportConfig[] = [];

export const hyperionImportConfigsSlice = createSlice({
  name: SLICE.HYPERION_IMPORT_CONFIGS,
  initialState,
  reducers: {
    clearHyperionImportConfigs: () => initialState,
    setHyperionImportConfigs: (_, action: PayloadAction<HyperionImportConfig[]>) => action.payload,
  },
});

export const { clearHyperionImportConfigs, setHyperionImportConfigs } = hyperionImportConfigsSlice.actions;

export default hyperionImportConfigsSlice.reducer;
