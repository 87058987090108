import { TitanPartner } from '@cognitiv/galaxy-api';

export const titan_partner_default: TitanPartner = {
  partner_id: 0,
  partner_name: '',
  salesforce_account_identifier: '',
  created_by: '',
  created_date: '',
  created_date_formatted: '',
  state_id: 0,
  state_name: '',
  uuid: '',
};
