import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import modalsSlice from 'ducks/modals/slices';
import overlaysSlice from 'ducks/overlays/slices';
import settingsSlice from 'ducks/settings/slices';
import userSlice from 'ducks/user/slices';
import { gemini } from 'products/core/operators/reducers';
import { hyperion } from 'products/hyperion/operators/reducers';
import { libra } from 'products/libra/operators/reducers';
import { orion } from 'products/orion/operators/reducers';
import { pollux } from 'products/pollux/operators/reducers';
import { titan } from 'products/titan/operators/reducers';
import { virgo } from 'products/virgo/operators/reducers';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const appReducer = combineReducers({
  settings: settingsSlice,
  modals: modalsSlice,
  overlays: overlaysSlice,
  user: userSlice,
  ...hyperion,
  ...titan,
  ...orion,
  ...virgo,
  ...libra,
  ...gemini,
  ...pollux,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === 'user/clearUser') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
