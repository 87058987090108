import { LibraDomainList } from '@cognitiv/galaxy-api';

export const libra_domain_list_default: LibraDomainList = {
  domain_list_id: 0,
  domain_list_name: '',
  attribute_id: 0,
  attribute_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  taxonomy_id: 0,
  taxomony_name: '',
  upload_state_id: 0,
  upload_state_name: '',
  domain_count: 0,
  created_by_user_id: 0,
  domain_count_formatted: '',
  upload_failure_reason: '',
  created_by: '',
  created_at_utc: '',
  uuid: '',
};
