import { HyperionReport } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionReport[] = [];

export const hyperionReportsSlice = createSlice({
  name: SLICE.HYPERION_REPORTS,
  initialState,
  reducers: {
    clearHyperionReports: () => initialState,
    setHyperionReports: (_, action: PayloadAction<HyperionReport[]>) => action.payload,
  },
});

export const { clearHyperionReports, setHyperionReports } = hyperionReportsSlice.actions;

export default hyperionReportsSlice.reducer;
