import { TitanCampaignNote } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_campaign_note_default } from 'products/titan/operators/campaign-note/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignNote = { ...titan_campaign_note_default };

export const titanCampaignNoteSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_NOTE,
  initialState,
  reducers: {
    clearTitanCampaignNote: () => initialState,
    setTitanCampaignNote: (_, action: PayloadAction<TitanCampaignNote>) => action.payload,
  },
});

export const { clearTitanCampaignNote, setTitanCampaignNote } = titanCampaignNoteSlice.actions;

export default titanCampaignNoteSlice.reducer;
