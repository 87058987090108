import { HyperionReportType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionReportType[] = [];

export const hyperionReportTypesSlice = createSlice({
  name: SLICE.HYPERION_REPORT_TYPES,
  initialState,
  reducers: {
    clearReportingReportTypes: () => initialState,
    setReportingReportTypes: (_, action: PayloadAction<HyperionReportType[]>) => action.payload,
  },
});

export const { clearReportingReportTypes, setReportingReportTypes } = hyperionReportTypesSlice.actions;

export default hyperionReportTypesSlice.reducer;
