import React from 'react';
import { TitanPage } from '@cognitiv/galaxy-api';
import classNames from 'classnames';
import { useSearchContext } from 'modals/search/context/SearchCommandContext';
import { Option } from 'modals/search/option/Option';

import cn from 'modals/search/Search.module.scss';

export const Options = ({ pages, title }: { pages: TitanPage[]; title: string }) => {
  const { selected } = useSearchContext();

  if (!pages.length) {
    return null;
  }
  return (
    <>
      <div className={classNames(cn.options, cn.sticky)}>
        <p className={cn.title}>{title}</p>
      </div>
      <div>
        {pages.map((page) => (
          <Option key={page.uuid} page={page} active={page.uuid === selected} />
        ))}
      </div>
    </>
  );
};
