import { VirgoDeal } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/virgo/operators/slice';

const initialState: VirgoDeal[] = [];

export const virgoDealsSlice = createSlice({
  name: SLICE.VIRGO_DEALS,
  initialState,
  reducers: {
    clearVirgoDeals: () => initialState,
    setVirgoDeals: (_, action: PayloadAction<VirgoDeal[]>) => action.payload,
  },
});

export const { clearVirgoDeals, setVirgoDeals } = virgoDealsSlice.actions;

export default virgoDealsSlice.reducer;
