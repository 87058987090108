import { TitanNotificationValue } from '@cognitiv/galaxy-api/';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_notification_slice_default } from 'products/titan/operators/delivery-values/defaults';
import { TitanNotificationDeliverySlice } from 'products/titan/operators/delivery-values/types';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanNotificationDeliverySlice = { ...titan_notification_slice_default };

export const titanDeliveryValuesSlice = createSlice({
  name: SLICE.TITAN_DELIVERY_VALUES,
  initialState,
  reducers: {
    clearTitanDeliverySlackValues: (state) => {
      state.slack = [];
    },
    clearTitanDeliveryEmailValues: (state) => {
      state.email = [];
    },
    setTitanDeliverySlackValues: (state, action: PayloadAction<TitanNotificationValue[]>) => {
      state.slack = action.payload;
    },
    setTitanDeliveryEmailValues: (state, action: PayloadAction<TitanNotificationValue[]>) => {
      state.email = action.payload;
    },
  },
});

export const { clearTitanDeliverySlackValues, clearTitanDeliveryEmailValues, setTitanDeliverySlackValues, setTitanDeliveryEmailValues } =
  titanDeliveryValuesSlice.actions;

export default titanDeliveryValuesSlice.reducer;
