import { TitanCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaign[] = [];

export const titanCampaignsSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGNS,
  initialState,
  reducers: {
    clearTitanCampaigns: () => initialState,
    setTitanCampaigns: (_, action: PayloadAction<TitanCampaign[]>) => action.payload,
  },
});

export const { clearTitanCampaigns, setTitanCampaigns } = titanCampaignsSlice.actions;

export default titanCampaignsSlice.reducer;
