import { TitanNotification } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanNotification[] = [];

export const titanNotificationsSlice = createSlice({
  name: SLICE.TITAN_NOTIFICATIONS,
  initialState,
  reducers: {
    clearTitanNotifications: () => initialState,
    setTitanNotifications: (_, action: PayloadAction<TitanNotification[]>) => action.payload,
  },
});

export const { clearTitanNotifications, setTitanNotifications } = titanNotificationsSlice.actions;

export default titanNotificationsSlice.reducer;
