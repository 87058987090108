import React, { useCallback, useEffect, useState } from 'react';
import { Banner, BANNER_ICON_POSITION, Button, DropdownFilter, Dropzone, Form, InputText } from '@cognitiv/cassiopeia-ui';
import { TitanAdvertiser, useClientContext } from '@cognitiv/galaxy-api';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { updateModal } from 'ducks/modals/slices';
import { useTheme } from 'hooks/useTheme';
import { DomainListFormProps } from 'products/libra/modals/manage-libra-domain-list/types';
import { selectTitanAdvertisers } from 'products/titan/operators/advertisers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'products/libra/modals/manage-libra-domain-list/ManageLibraDomainList.module.scss';

export const DomainListForm = ({
  form,
  files,
  onChangeDomainList,
  onChangeConfiguration,
  onSubmit,
  entity_exists,
  upload_error_message,
}: DomainListFormProps) => {
  const dispatch = useAppDispatch();
  const { Titan, handleError } = useClientContext();
  const titan_advertisers = useAppSelector(selectTitanAdvertisers);
  const [advertisers, setAdvertisers] = useState<TitanAdvertiser[]>([]);

  useEffect(() => {
    setAdvertisers(titan_advertisers);
  }, [titan_advertisers]);

  const {
    theme: { banner_action, generic },
  } = useTheme();

  const onClose = useCallback(() => {
    dispatch(updateModal({ manage_libra_domain_list: { is_open: false } }));
  }, [dispatch]);

  const onSelectAdvertiser = useCallback(
    ({ advertiser_name, advertiser_id }: TitanAdvertiser) => onChangeDomainList({ advertiser_name, advertiser_id }),
    [onChangeDomainList],
  );

  const onChangeAdvertiser = useCallback(
    async ({ value }: { value: string }) => {
      try {
        const advertisers = await Titan.getTitanAdvertisers({ filter: value });
        setAdvertisers(advertisers);
      } catch (error) {
        handleError(error);
      }
    },
    [Titan, handleError],
  );

  return (
    <Form onSubmit={onSubmit}>
      <div className={cn.body}>
        {upload_error_message && (
          <Banner
            color_primary={generic.banner_primary}
            color_secondary={generic.banner_secondary}
            theme={banner_action}
            title={`Upload of domain list failed. Please try again. Reason: ${upload_error_message}`}
            icon_position={BANNER_ICON_POSITION.TOP}
            tape={false}
            icon={faCircleExclamation}
            action
          />
        )}
        <InputText
          label="List Name"
          value={form.domain_list_name}
          onChange={({ value }) => onChangeDomainList({ domain_list_name: value })}
          auto_focus
        />
        <DropdownFilter
          label="Advertiser"
          option_key="advertiser_name"
          debounce={300}
          options={advertisers}
          value={form.advertiser_name ?? ''}
          onSelect={onSelectAdvertiser}
          onChange={onChangeAdvertiser}
          disabled={entity_exists}
        />
        <Dropzone
          label="File"
          message="Drop or Upload a Domain List"
          onSelect={(file) => onChangeConfiguration({ files: file })}
          onDelete={() => onChangeConfiguration({ files: [] })}
          files={files}
          accept={['.csv']}
        />
      </div>
      <div className={cn.footer}>
        <Button variant="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!files.length} type="submit">
          {entity_exists ? 'Save' : 'Create'}
        </Button>
      </div>
    </Form>
  );
};
