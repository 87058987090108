import { TitanDeliveryType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanDeliveryType[] = [];

export const titanDeliveryTypesListSlice = createSlice({
  name: SLICE.TITAN_DELIVERY_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanDeliveryTypesList: () => {
      return initialState;
    },
    setTitanDeliveryTypesList: (_, action: PayloadAction<TitanDeliveryType[]>) => action.payload,
  },
});

export const { clearTitanDeliveryTypesList, setTitanDeliveryTypesList } = titanDeliveryTypesListSlice.actions;

export default titanDeliveryTypesListSlice.reducer;
