import React, { useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ClientProvider } from '@cognitiv/galaxy-api';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { selectUser } from 'ducks/user/selectors';
import { clearUser } from 'ducks/user/slices';
import { setAutoFreeze } from 'immer';
import CoreTemplate from 'products/core/template/ApplicationTemplate';
import ReportingTemplate from 'products/hyperion/template/ApplicationTemplate';
import LibraTemplate from 'products/libra/template/ApplicationTemplate';
import LunaTemplate from 'products/luna/template/ApplicationTemplate';
import OrionTemplate from 'products/orion/template/ApplicationTemplate';
import PolluxTemplate from 'products/pollux/template/ApplicationTemplate';
import ErrorPage from 'products/public/pages/error/ErrorPage';
import LoginPage from 'products/public/pages/login/LoginPage';
import RequestPasswordPage from 'products/public/pages/request-password/RequestPasswordPage';
import ResetPasswordPage from 'products/public/pages/reset-password/ResetPasswordPage';
import ErrorTemplate from 'products/public/pages/templates/ErrorTemplate';
import TitanTemplate from 'products/titan/template/ApplicationTemplate';
import VirgoTemplate from 'products/virgo/template/ApplicationTemplate';
import { CORE, HYPERION, LIBRA, LUNA, ORION, POLLUX, PRIVATE, PUBLIC, TITAN, VIRGO } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Suspense } from 'Suspense';
import PrivateTemplate from 'templates/PrivateTemplate';
import PublicTemplate from 'templates/PublicTemplate';
import { deleteLocalStorage, getLocalStorage } from 'utils';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_SERVICE,
  REACT_APP_GEMINI,
  REACT_APP_SOL,
  REACT_APP_HYPERION,
  REACT_APP_TITAN,
  REACT_APP_ORION,
  REACT_APP_LIBRA,
  REACT_APP_VIRGO,
  REACT_APP_POLLUX,
} = process.env;

setAutoFreeze(false);

export const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser, shallowEqual);

  const errorCallback = useCallback(
    (err: any) => {
      dispatch(updateSettings({ loading: false }));
      if (err.status === 401 || err.error === 'HashMismatch') {
        dispatch(clearUser());
        deleteLocalStorage('user');
        return err;
      }
      dispatch(
        updateModal({
          error: {
            is_open: true,
            name: err.name || null,
            message: err.message || null,
            error: err.error || null,
            status: err.status || null,
            stack: err.stack || null,
            raw: err.raw || null,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const { private_key, public_key } = user;
    const settings = getLocalStorage('settings');
    const theme = settings.theme || 'light';

    if (private_key && public_key) {
      dispatch(updateSettings({ ...settings, theme }));
      return;
    }
    dispatch(updateSettings({ theme }));
  }, [dispatch, user, user.private_key, user.public_key]);

  return (
    <ClientProvider
      service={REACT_APP_SERVICE || 'error'}
      environment={REACT_APP_ENVIRONMENT || 'development'}
      version={REACT_APP_VERSION || '0.0.0'}
      host={{
        SOL: REACT_APP_SOL,
        GEMINI: REACT_APP_GEMINI,
        HYPERION: REACT_APP_HYPERION,
        TITAN: REACT_APP_TITAN,
        LIBRA: REACT_APP_LIBRA,
        ORION: REACT_APP_ORION,
        VIRGO: REACT_APP_VIRGO,
        POLLUX: REACT_APP_POLLUX,
      }}
      errorCallback={errorCallback}
    >
      <BrowserRouter>
        <Routes>
          <Route path={PRIVATE.ROOT.path} element={<Navigate to={PUBLIC.LOGIN.path} />} />
          <Route path={PUBLIC.ROOT.path} element={<PublicTemplate />}>
            <Route path={PUBLIC.LOGIN.path} element={<LoginPage />} />
            <Route path={PUBLIC.PASSWORD_REQUEST.path} element={<RequestPasswordPage />} />
            <Route path={PUBLIC.PASSWORD_RESET.path} element={<ResetPasswordPage />} />
          </Route>
          <Route path={PRIVATE.ROOT.path} element={<PrivateTemplate />}>
            <Route path={LUNA.APPLICATION.path} element={<LunaTemplate />}>
              <Route index element={<Suspense element={LUNA.APPLICATION.element} />} />
            </Route>
            <Route path={TITAN.APPLICATION.path} element={<TitanTemplate />}>
              <Route path={TITAN.ADVERTISERS.path} element={<Suspense element={TITAN.ADVERTISERS.element} />} />
              <Route path={TITAN.PARTNERS.path} element={<Suspense element={TITAN.PARTNERS.element} />} />
              <Route path={TITAN.NOTIFICATIONS.path} element={<Suspense element={TITAN.NOTIFICATIONS.element} />} />
              <Route path={TITAN.EVENT_SOURCES.path} element={<Suspense element={TITAN.EVENT_SOURCES.element} />} />
              <Route path={TITAN.UNIVERSAL_PIXEL.path} element={<Suspense element={TITAN.UNIVERSAL_PIXEL.element} />}>
                <Route path={TITAN.UNIVERSAL_PIXEL.DEFAULT.path} element={<Suspense element={TITAN.UNIVERSAL_PIXEL.DEFAULT.element} />} />
                <Route path={TITAN.UNIVERSAL_PIXEL.PROPERTIES.path} element={<Suspense element={TITAN.UNIVERSAL_PIXEL.PROPERTIES.element} />} />
                <Route
                  path={TITAN.UNIVERSAL_PIXEL.VIRTUAL_EVENTS.path}
                  element={<Suspense element={TITAN.UNIVERSAL_PIXEL.VIRTUAL_EVENTS.element} />}
                />
              </Route>
              <Route path={TITAN.INDIVIDUAL_PIXEL.path} element={<Suspense element={TITAN.INDIVIDUAL_PIXEL.element} />}>
                <Route path={TITAN.INDIVIDUAL_PIXEL.DEFAULT.path} element={<Suspense element={TITAN.INDIVIDUAL_PIXEL.DEFAULT.element} />} />
                <Route
                  path={TITAN.INDIVIDUAL_PIXEL.VIRTUAL_EVENTS.path}
                  element={<Suspense element={TITAN.INDIVIDUAL_PIXEL.VIRTUAL_EVENTS.element} />}
                />
              </Route>
              <Route path={TITAN.VIRTUAL_EVENT.path} element={<Suspense element={TITAN.VIRTUAL_EVENT.element} />}>
                <Route path={TITAN.VIRTUAL_EVENT.DEFAULT.path} element={<Suspense element={TITAN.VIRTUAL_EVENT.DEFAULT.element} />} />
                <Route path={TITAN.VIRTUAL_EVENT.CONDITIONS.path} element={<Suspense element={TITAN.VIRTUAL_EVENT.CONDITIONS.element} />} />
              </Route>
              <Route path={TITAN.LOCATION.path} element={<Suspense element={TITAN.LOCATION.element} />}>
                <Route path={TITAN.LOCATION.DEFAULT.path} element={<Suspense element={TITAN.LOCATION.DEFAULT.element} />} />
                <Route path={TITAN.LOCATION.PROPERTIES.path} element={<Suspense element={TITAN.LOCATION.PROPERTIES.element} />} />
                <Route path={TITAN.LOCATION.VIRTUAL_EVENTS.path} element={<Suspense element={TITAN.LOCATION.VIRTUAL_EVENTS.element} />} />
              </Route>
              <Route path={TITAN.CPG.path} element={<Suspense element={TITAN.CPG.element} />}>
                <Route path={TITAN.CPG.DEFAULT.path} element={<Suspense element={TITAN.CPG.DEFAULT.element} />} />
                <Route path={TITAN.CPG.PROPERTIES.path} element={<Suspense element={TITAN.CPG.PROPERTIES.element} />} />
                <Route path={TITAN.CPG.VIRTUAL_EVENTS.path} element={<Suspense element={TITAN.CPG.VIRTUAL_EVENTS.element} />} />
              </Route>
              <Route path={TITAN.CREATIVES.path} element={<Suspense element={TITAN.CREATIVES.element} />} />
              <Route path={TITAN.CREATIVE.path} element={<Suspense element={TITAN.CREATIVE.element} />}>
                <Route path={TITAN.CREATIVE.DEFAULT.path} element={<Suspense element={TITAN.CREATIVE.DEFAULT.element} />} />
                <Route path={TITAN.CREATIVE.EXPANDED_CREATIVE.path} element={<Suspense element={TITAN.CREATIVE.EXPANDED_CREATIVE.element} />} />
                <Route path={TITAN.CREATIVE.ADDONS.path} element={<Suspense element={TITAN.CREATIVE.ADDONS.element} />} />
                <Route path={TITAN.CREATIVE.EVENT_TESTER.path} element={<Suspense element={TITAN.CREATIVE.EVENT_TESTER.element} />} />
                <Route path={TITAN.CREATIVE.THIRD_PARTY_TESTER.path} element={<Suspense element={TITAN.CREATIVE.THIRD_PARTY_TESTER.element} />} />
                <Route path={TITAN.CREATIVE.EVENT_LOG.path} element={<Suspense element={TITAN.CREATIVE.EVENT_LOG.element} />} />
                <Route path={TITAN.CREATIVE.AUDIT_LOG.path} element={<Suspense element={TITAN.CREATIVE.AUDIT_LOG.element} />} />
              </Route>
            </Route>
            <Route path={LIBRA.APPLICATION.path} element={<LibraTemplate />}>
              <Route path={LIBRA.DOMAIN_LISTS.path} element={<Suspense element={LIBRA.DOMAIN_LISTS.element} />} />
              <Route path={LIBRA.DSP_IDENTIFIERS.path} element={<Suspense element={LIBRA.DSP_IDENTIFIERS.element} />} />
            </Route>
            <Route path={HYPERION.APPLICATION.path} element={<ReportingTemplate />}>
              <Route path={HYPERION.CUSTOM_REPORTS.path} element={<Suspense element={HYPERION.CUSTOM_REPORTS.element} />} />
              <Route path={HYPERION.CS_CAMPAIGN_TRACKER.path} element={<Suspense element={HYPERION.CS_CAMPAIGN_TRACKER.element} />}>
                <Route
                  path={HYPERION.CS_CAMPAIGN_TRACKER.DEFAULT.path}
                  element={<Suspense element={HYPERION.CS_CAMPAIGN_TRACKER.DEFAULT.element} />}
                />
                <Route
                  path={HYPERION.CS_CAMPAIGN_TRACKER.ALL_REGIONS.path}
                  element={<Suspense element={HYPERION.CS_CAMPAIGN_TRACKER.DEFAULT.element} />}
                />
                <Route
                  path={HYPERION.CS_CAMPAIGN_TRACKER.CENTRAL.path}
                  element={<Suspense element={HYPERION.CS_CAMPAIGN_TRACKER.DEFAULT.element} />}
                />
                <Route path={HYPERION.CS_CAMPAIGN_TRACKER.EAST.path} element={<Suspense element={HYPERION.CS_CAMPAIGN_TRACKER.DEFAULT.element} />} />
                <Route path={HYPERION.CS_CAMPAIGN_TRACKER.WEST.path} element={<Suspense element={HYPERION.CS_CAMPAIGN_TRACKER.DEFAULT.element} />} />
              </Route>
              <Route path={HYPERION.DS_CAMPAIGN_TRACKER.path} element={<Suspense element={HYPERION.DS_CAMPAIGN_TRACKER.element} />} />
              <Route path={HYPERION.CUSTOM_REPORT.path} element={<Suspense element={HYPERION.CUSTOM_REPORT.element} />} />
              <Route path={HYPERION.INBOUND_REPORT_INBOX.path} element={<Suspense element={HYPERION.INBOUND_REPORT_INBOX.element} />} />
              <Route path={HYPERION.IMPORT_CONFIGURATIONS.path} element={<Suspense element={HYPERION.IMPORT_CONFIGURATIONS.element} />} />
              <Route path={HYPERION.IMPORT_CONFIGURATION.path} element={<Suspense element={HYPERION.IMPORT_CONFIGURATION.element} />}>
                <Route
                  path={HYPERION.IMPORT_CONFIGURATION.DEFAULT.path}
                  element={<Suspense element={HYPERION.IMPORT_CONFIGURATION.DEFAULT.element} />}
                />
                <Route
                  path={HYPERION.IMPORT_CONFIGURATION.MAPPINGS.path}
                  element={<Suspense element={HYPERION.IMPORT_CONFIGURATION.MAPPINGS.element} />}
                />
                <Route
                  path={HYPERION.IMPORT_CONFIGURATION.REPORT_HISTORY.path}
                  element={<Suspense element={HYPERION.IMPORT_CONFIGURATION.REPORT_HISTORY.element} />}
                />
                <Route
                  path={HYPERION.IMPORT_CONFIGURATION.UNJOINED_ROWS.path}
                  element={<Suspense element={HYPERION.IMPORT_CONFIGURATION.UNJOINED_ROWS.element} />}
                />
              </Route>
              <Route path={HYPERION.DSP_LINE_ITEM_DEAL_MAPPINGS.path} element={<Suspense element={HYPERION.DSP_LINE_ITEM_DEAL_MAPPINGS.element} />} />
            </Route>
            <Route path={VIRGO.APPLICATION.path} element={<VirgoTemplate />}>
              <Route path={VIRGO.DEFAULT.path} element={<Suspense element={VIRGO.DEFAULT.element} />} />
              <Route path={VIRGO.CAMPAIGNS.path} element={<Suspense element={VIRGO.CAMPAIGNS.element} />} />
              <Route path={VIRGO.DEALS.path} element={<Suspense element={VIRGO.DEALS.element} />} />
              <Route path={VIRGO.DEAL.path} element={<Suspense element={VIRGO.DEAL.element} />}>
                <Route path={VIRGO.DEAL.FILTER.path} element={<Suspense element={VIRGO.DEAL.FILTER.element} />} />
                <Route path={VIRGO.DEAL.SSP_REPORT.path} element={<Suspense element={VIRGO.DEAL.SSP_REPORT.element} />} />
              </Route>
              <Route path={VIRGO.CAMPAIGN.path} element={<Suspense element={VIRGO.CAMPAIGN.element} />}>
                <Route path={VIRGO.CAMPAIGN.DEFAULT.path} element={<Suspense element={VIRGO.CAMPAIGN.DEFAULT.element} />} />
                <Route path={VIRGO.CAMPAIGN.PERFORMANCE.path} element={<Suspense element={VIRGO.CAMPAIGN.PERFORMANCE.element} />} />
                <Route path={VIRGO.CAMPAIGN.DEALS.path} element={<Suspense element={VIRGO.CAMPAIGN.DEALS.element} />} />
                <Route path={VIRGO.CAMPAIGN.ADSERVER_REPORT.path} element={<Suspense element={VIRGO.CAMPAIGN.ADSERVER_REPORT.element} />} />
                <Route path={VIRGO.CAMPAIGN.DSP_REPORT.path} element={<Suspense element={VIRGO.CAMPAIGN.DSP_REPORT.element} />} />
                <Route path={VIRGO.CAMPAIGN.SSP_REPORT.path} element={<Suspense element={VIRGO.CAMPAIGN.SSP_REPORT.element} />} />
                <Route path={VIRGO.CAMPAIGN.DEAL_MAPPINGS.path} element={<Suspense element={VIRGO.CAMPAIGN.DEAL_MAPPINGS.element} />} />
                <Route path={VIRGO.CAMPAIGN.IMPORTERS.path} element={<Suspense element={VIRGO.CAMPAIGN.IMPORTERS.element} />} />
                <Route path={VIRGO.CAMPAIGN.TEAM.path} element={<Suspense element={VIRGO.CAMPAIGN.TEAM.element} />} />
                <Route path={VIRGO.CAMPAIGN.NOTES.path} element={<Suspense element={VIRGO.CAMPAIGN.NOTES.element} />} />
              </Route>
            </Route>
            <Route path={ORION.APPLICATION.path} element={<OrionTemplate />}>
              <Route path={ORION.DEFAULT.path} element={<Suspense element={ORION.DEFAULT.element} />} />
              <Route path={ORION.DOMAIN_LISTS.path} element={<Suspense element={ORION.DOMAIN_LISTS.element} />} />
              <Route path={ORION.DOMAIN_LIST.path} element={<Suspense element={ORION.DOMAIN_LIST.element} />} />
              <Route path={ORION.CAMPAIGNS.path} element={<Suspense element={ORION.CAMPAIGNS.element} />} />
              <Route path={ORION.SEGMENTS.path} element={<Suspense element={ORION.SEGMENTS.element} />} />
              <Route path={ORION.DEALS.path} element={<Suspense element={ORION.DEALS.element} />} />
              <Route path={ORION.SEGMENT.path} element={<Suspense element={ORION.SEGMENT.element} />}>
                <Route path={ORION.SEGMENT.FILTERS.path} element={<Suspense element={ORION.SEGMENT.FILTERS.element} />} />
              </Route>
              <Route path={ORION.CAMPAIGN.path} element={<Suspense element={ORION.CAMPAIGN.element} />}>
                <Route path={ORION.CAMPAIGN.DEFAULT.path} element={<Suspense element={ORION.CAMPAIGN.DEFAULT.element} />} />
                <Route path={ORION.CAMPAIGN.PERFORMANCE.path} element={<Suspense element={ORION.CAMPAIGN.PERFORMANCE.element} />} />
                <Route path={ORION.CAMPAIGN.SEGMENTS.path} element={<Suspense element={ORION.CAMPAIGN.SEGMENTS.element} />} />
                <Route path={ORION.CAMPAIGN.DEALS.path} element={<Suspense element={ORION.CAMPAIGN.DEALS.element} />} />
                <Route path={ORION.CAMPAIGN.DEAL_MAPPINGS.path} element={<Suspense element={ORION.CAMPAIGN.DEAL_MAPPINGS.element} />} />
                <Route path={ORION.CAMPAIGN.ADSERVER_REPORT.path} element={<Suspense element={ORION.CAMPAIGN.ADSERVER_REPORT.element} />} />
                <Route path={ORION.CAMPAIGN.DSP_REPORT.path} element={<Suspense element={ORION.CAMPAIGN.DSP_REPORT.element} />} />
                <Route path={ORION.CAMPAIGN.SSP_REPORT.path} element={<Suspense element={ORION.CAMPAIGN.SSP_REPORT.element} />} />
                <Route path={ORION.CAMPAIGN.IMPORTERS.path} element={<Suspense element={ORION.CAMPAIGN.IMPORTERS.element} />} />
                <Route path={ORION.CAMPAIGN.TEAM.path} element={<Suspense element={ORION.CAMPAIGN.TEAM.element} />} />
                <Route path={ORION.CAMPAIGN.NOTES.path} element={<Suspense element={ORION.CAMPAIGN.NOTES.element} />} />
              </Route>
              <Route path={ORION.DEAL.path} element={<Suspense element={ORION.DEAL.element} />}>
                <Route path={ORION.DEAL.DEFAULT.path} element={<Suspense element={ORION.DEAL.DEFAULT.element} />} />
                <Route path={ORION.DEAL.FILTER.path} element={<Suspense element={ORION.DEAL.FILTER.element} />} />
                <Route path={ORION.DEAL.SSP_REPORT.path} element={<Suspense element={ORION.DEAL.SSP_REPORT.element} />} />
              </Route>
            </Route>
            <Route path={CORE.APPLICATION.path} element={<CoreTemplate />}>
              <Route path={CORE.DEFAULT.path} element={<Suspense element={CORE.DEFAULT.element} />} />
              <Route path={CORE.CAMPAIGNS.path} element={<Suspense element={CORE.CAMPAIGNS.element} />} />
              <Route path={CORE.CAMPAIGN.path} element={<Suspense element={CORE.CAMPAIGN.element} />}>
                <Route path={CORE.CAMPAIGN.DEFAULT.path} element={<Suspense element={CORE.CAMPAIGN.DEFAULT.element} />} />
                <Route path={CORE.CAMPAIGN.PERFORMANCE.path} element={<Suspense element={CORE.CAMPAIGN.PERFORMANCE.element} />} />
                <Route path={CORE.CAMPAIGN.DCPM.path} element={<Suspense element={CORE.CAMPAIGN.DCPM.element} />} />
                <Route path={CORE.CAMPAIGN.IMPORTERS.path} element={<Suspense element={CORE.CAMPAIGN.IMPORTERS.element} />} />
                <Route path={CORE.CAMPAIGN.TEAM.path} element={<Suspense element={CORE.CAMPAIGN.TEAM.element} />} />
                <Route path={CORE.CAMPAIGN.NOTES.path} element={<Suspense element={CORE.CAMPAIGN.NOTES.element} />} />
              </Route>
            </Route>
            <Route path={POLLUX.APPLICATION.path} element={<PolluxTemplate />}>
              {/* <Route path={POLLUX.FILTERS.path} element={<Suspense element={POLLUX.FILTERS.element} />} />
              <Route path={POLLUX.FILTER.path} element={<Suspense element={POLLUX.FILTER.element} />} /> */}
            </Route>
            <Route path="*" element={<ErrorTemplate />}>
              <Route path="*" element={<ErrorPage path={LUNA.APPLICATION.path} page="Products" />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ClientProvider>
  );
};
