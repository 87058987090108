import { TitanEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSource[] = [];

export const titanEventSourcesSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCES,
  initialState,
  reducers: {
    clearTitanEventSources: () => initialState,
    setTitanEventSources: (_, action: PayloadAction<TitanEventSource[]>) => action.payload,
  },
});

export const { clearTitanEventSources, setTitanEventSources } = titanEventSourcesSlice.actions;

export default titanEventSourcesSlice.reducer;
