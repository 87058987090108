import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'ducks/slice';
import { User } from 'ducks/user/types';
import { deleteLocalStorage } from 'utils';

const initialState: User = {
  error: null,
};

export const userSlice = createSlice({
  name: SLICE.USER,
  initialState,
  reducers: {
    setUser: (_state, action) => {
      return action.payload;
    },
    clearUser: () => {
      deleteLocalStorage('user');
      return initialState;
    },
  },
  // removed the rejected case because authentication will never reject
  // we capture the error and populate that in the login
});

export const { clearUser, setUser } = userSlice.actions;

export default userSlice.reducer;
