import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { selectSettings } from 'ducks/settings/selectors';
import { updateSettings } from 'ducks/settings/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export enum GROUP {
  CONFIGURATION = 'configuration',
  CORE = 'core',
  VIRGO = 'curation',
  ORION = 'contextual',
  REPORTING = 'reporting',
}

export enum PAGE {
  APPLICATIONS = 'applications',
  CORE = 'core',
  VIRGO = 'curation',
  ORION = 'contextual',
  REPORTING = 'reporting',
  LISTS = 'lists',
  ADVERTISERS = 'advertisers',
  PARTNERS = 'partners',
  DSP_IDENTIFIERS = 'dsp-identifiers',
  EMPTY = '',
  CAMPAIGNS = 'campaigns',
  CREATIVES = 'creatives',
  AUDIENCES = 'audiences',
  SEGMENTS = 'segments',
  DEALS = 'deals',
  DOMAIN_LISTS = 'domain-lists',
  IMPORT_CONFIGURATIONS = 'import-configurations',
  INBOUND_REPORT_INBOX = 'inbound-report-inbox',
  REPORTS = 'reports',
  DASHBOARDS = 'dashboards',
  CS_CAMPAIGN_TRACKER = 'cs-campaign-tracker',
  DS_CAMPAIGN_TRACKER = 'ds-campaign-tracker',
  EVENT_SOURCES = 'event-sources',
  DSP_LINE_ITEM_DEAL_MAPPINGS = 'dsp-line-item-deal-mappings',
  NOTIFICATIONS = 'notifications',
  FILTERS = 'filters',
}

export const useMenu = () => {
  const { pathname } = useLocation();
  const { is_menu_open, is_application_menu_open } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();

  const [expanded_menu_group, openMenuGroup] = useState<GROUP | undefined | GROUP[]>(undefined);

  const getApplicationPage = (deep: number) => {
    const paths = pathname.split('/');
    const application = paths[deep] || '';

    if (application.includes('?')) {
      return application.split('?')[0];
    }
    return application;
  };

  const handleOpenMenuGroup = useCallback(
    (menu?: GROUP) => {
      if (is_menu_open) {
        if (menu && expanded_menu_group?.includes(menu)) {
          openMenuGroup((prevState) => {
            return [...((prevState as GROUP[]) || [])?.filter((item) => item !== menu)];
          });
        } else {
          openMenuGroup((prevState) => [...((prevState as GROUP[]) || []), menu as GROUP]);
        }
        return;
      }

      openMenuGroup(menu);
    },
    [expanded_menu_group, is_menu_open],
  );

  const handleExpand = useCallback(() => {
    if (is_menu_open) return;
    handleOpenMenuGroup();
  }, [handleOpenMenuGroup, is_menu_open]);

  const getApplicationGroup = () => {
    const paths = pathname.split('/');

    switch (true) {
      case paths[2] === PAGE.ORION:
        return GROUP.ORION;
      case paths[2] === PAGE.CORE:
        return GROUP.CORE;
      case paths[2] === PAGE.VIRGO:
        return GROUP.VIRGO;
      case paths[2] === PAGE.REPORTING:
        return GROUP.REPORTING;
      case paths[2] === PAGE.CREATIVES:
      case paths[2] === PAGE.EVENT_SOURCES:
      case paths[2] === PAGE.LISTS:
      case paths[2] === PAGE.ADVERTISERS:
      case paths[2] === PAGE.PARTNERS:
      case paths[2] === PAGE.DSP_IDENTIFIERS:
      case paths[2] === PAGE.DOMAIN_LISTS:
      case paths[2] === PAGE.NOTIFICATIONS:
      case paths[2] === PAGE.FILTERS:
      case paths[2] === undefined:
        return GROUP.CONFIGURATION;
      default:
        return '';
    }
  };

  const getPage = () => {
    const paths = pathname.split('/');
    const page = paths[paths.length - 1];

    if (page.includes('?')) {
      return page.split('?')[0];
    }

    return page;
  };

  const group = getApplicationGroup();
  const page = getPage();

  const setMenu = useCallback(
    (is_open: boolean) => {
      dispatch(updateSettings({ is_menu_open: is_open }));
    },
    [dispatch],
  );

  const setApplicationMenu = useCallback(
    (is_open: boolean) => {
      dispatch(updateSettings({ is_application_menu_open: is_open }));
    },
    [dispatch],
  );

  return {
    group,
    getApplicationPage,
    page,
    is_menu_open,
    is_application_menu_open,
    setMenu,
    setApplicationMenu,
    expanded_menu_group,
    handleOpenMenuGroup,
    handleExpand,
  };
};
