import { HyperionFilterTimestamp } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_filter_timestamps } from 'products/hyperion/operators/filter-timestamps/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionFilterTimestamp[] = [...hyperion_filter_timestamps];

export const hyperionFilterTimestampsSlice = createSlice({
  name: SLICE.HYPERION_FILTER_TIMESTAMPS,
  initialState,
  reducers: {
    clearHyperionFilterTimestamps: () => initialState,
    setHyperionFilterTimestamps: (_, action: PayloadAction<HyperionFilterTimestamp[]>) => action.payload,
  },
});

export const { clearHyperionFilterTimestamps, setHyperionFilterTimestamps } = hyperionFilterTimestampsSlice.actions;

export default hyperionFilterTimestampsSlice.reducer;
