import { TitanSalesforceFlight } from '@cognitiv/galaxy-api';
import { createSlice } from '@reduxjs/toolkit';
import { titan_salesforce_flight_default } from 'products/titan/operators/salesforce-flight/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanSalesforceFlight = {
  ...titan_salesforce_flight_default,
};

export const titanSalesforceFlightSlice = createSlice({
  name: SLICE.TITAN_SALESFORCE_FLIGHT,
  initialState,
  reducers: {
    clearTitanSalesforceFlight: () => initialState,
    setTitanSalesforceFlight: (_, action) => action.payload,
  },
});

export const { clearTitanSalesforceFlight, setTitanSalesforceFlight } = titanSalesforceFlightSlice.actions;

export default titanSalesforceFlightSlice.reducer;
