import { TitanSalesRegion } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanSalesRegion[] = [];

export const titanSalesRegionsListSlice = createSlice({
  name: SLICE.TITAN_SALES_REGIONS_LIST,
  initialState,
  reducers: {
    clearTitanSalesRegionsList: () => initialState,
    setTitanSalesRegionsList: (_, action: PayloadAction<TitanSalesRegion[]>) => action.payload,
  },
});

export const { clearTitanSalesRegionsList, setTitanSalesRegionsList } = titanSalesRegionsListSlice.actions;

export default titanSalesRegionsListSlice.reducer;
