import React, { useCallback } from 'react';

export const PWA_LOCAL_STORAGE_KEY = 'pwa-install';

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
}

export function useAddToHomeScreenPrompt(): IBeforeInstallPromptEvent | null {
  const [prompt, showInstallPrompt] = React.useState<IBeforeInstallPromptEvent | null>(null);

  const ready = useCallback((e: IBeforeInstallPromptEvent) => {
    e.preventDefault();
    showInstallPrompt(e);
  }, []);

  React.useEffect(() => {
    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, [ready]);

  return prompt;
}
