import { TitanKpiType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanKpiType[] = [];

export const titanKpiTypesListSlice = createSlice({
  name: SLICE.TITAN_KPI_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanKpiTypesList: () => {
      return initialState;
    },
    setTitanKpiTypesList: (_, action: PayloadAction<TitanKpiType[]>) => action.payload,
  },
});

export const { clearTitanKpiTypesList, setTitanKpiTypesList } = titanKpiTypesListSlice.actions;

export default titanKpiTypesListSlice.reducer;
