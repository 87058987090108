import { TitanNotificationType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanNotificationType[] = [];

export const titanNotificationTypesListSlice = createSlice({
  name: SLICE.TITAN_NOTIFICATION_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanNotificationTypesList: () => initialState,
    setTitanNotificationTypesList: (_, action: PayloadAction<TitanNotificationType[]>) => action.payload,
  },
});

export const { clearTitanNotificationTypesList, setTitanNotificationTypesList } = titanNotificationTypesListSlice.actions;

export default titanNotificationTypesListSlice.reducer;
