export enum ORION_DOMAIN_LIST_STATE_ID {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum ORION_SEGMENT_STATE_ID {
  ACTIVE = 1,
  PAUSED = 2,
  INACTIVE = 3,
  ARCHIVED = 4,
}

export enum DOMAIN_LIST_SCOPE {
  GLOBAL = 1,
  ADVERTISER = 2,
  SEGMENT = 3,
}

export enum ORION_EMBEDDING_ACTION {
  INSERT = 'Insert',
  INSERT_GPT = 'GPT',
  INSERT_URL = 'Insert URL',
  UPDATE = 'Update',
  REMOVE = 'Remove',
}

export enum ORION_ATTRIBUTE_ACTION {
  INSERT = 'Insert',
  REPLACE = 'Replace',
  REMOVE = 'Remove',
}

export enum PULSE_ACTION {
  LIKE = 'like',
  DISLIKE = 'dislike',
  EXCLUDE = 'exclude',
}

export enum ORION_DOMAIN_LIST_UPLOAD_STATE {
  PENDING = 1,
  SUCCEEDED = 4,
  FAILED = 99,
}

export enum ORION_DOMAIN_LIST_DOWNLOAD_STATE {
  QUEUED = 1,
  PROCESSING = 2,
  SUCCEEDED = 3,
  FAILED = 4,
}
