import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { setReportingReportTypes } from 'products/hyperion/operators/report-types/slices';
import { TITAN_DELIVERY_TYPE_ID } from 'products/titan/enums';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanCampaigns } from 'products/titan/operators/campaigns/slices';
import { setTitanDeliveryEmailValues, setTitanDeliverySlackValues } from 'products/titan/operators/delivery-values/slices';
import { setTitanProductTypesList } from 'products/titan/operators/product-types-list/slices';
import { setTitanSalesRegionsList } from 'products/titan/operators/sales-regions-list/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/hyperion/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { hyperion } = useAppSelector(selectSettings);
  const { Hyperion, Titan, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!hyperion) return;

        const [
          reporting_types,
          titan_advertisers,
          titan_campaigns,
          titan_product_types_list,
          titan_sales_regions_list,
          slack_delivery_destinations,
          email_delivery_destinations,
          // hyperion_intervals_list,
          // hyperion_output_types_list,
          // hyperion_delivery_types_list,
        ] = await Promise.all([
          Hyperion.getHyperionReportTypes(),
          Titan.getTitanAdvertisers(),
          Titan.getTitanCampaigns(),
          Titan.getTitanProductTypesList(),
          Titan.getTitanSalesRegionsList(),
          Titan.getTitanNotificationDeliveryValues(TITAN_DELIVERY_TYPE_ID.SLACK),
          Titan.getTitanNotificationDeliveryValues(TITAN_DELIVERY_TYPE_ID.EMAIL),
          // Hyperion.getHyperionIntervalsList(),
          // Hyperion.getHyperionOutputTypesList(),
          // Hyperion.getHyperionDeliveryTypesList(),  // TO DO: Get from Titan instead?
        ]);

        // console.log(hyperion_intervals_list, hyperion_output_types_list, hyperion_delivery_types_list);

        batch(() => {
          dispatch(setReportingReportTypes(reporting_types));
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanCampaigns(titan_campaigns));
          dispatch(setTitanProductTypesList(titan_product_types_list));
          dispatch(setTitanSalesRegionsList(titan_sales_regions_list));
          dispatch(setTitanDeliverySlackValues(slack_delivery_destinations));
          dispatch(setTitanDeliveryEmailValues(email_delivery_destinations));
          // dispatch(setHyperionIntervalsList(hyperion_intervals_list));
          // dispatch(setHyperionOutputTypesList(hyperion_output_types_list));
          // dispatch(setHyperionDeliveryTypesList(hyperion_delivery_types_list));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (hyperion) {
      getData();
    }
  }, [dispatch, handleError, Titan, Hyperion, hyperion]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};
