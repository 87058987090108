import {
  TITAN_CAMPAIGN_NOTE_CATEGORY_ID,
  TITAN_CAMPAIGN_OPTION_ID,
  TITAN_CAMPAIGN_STATE_ID,
  TITAN_DELIVERY_SOURCE_ID,
  TITAN_DELIVERY_TYPE_ID,
  TITAN_EVENT_SOURCE_OPTION_ID,
  TITAN_EVENT_SOURCE_STATE_ID,
} from 'products/titan/enums';
import { TITAN_EVENT_SOURCE_TYPE_ID } from 'products/titan/operators/event-source-types-list/enum';
import { TitanCampaignOptionType, TitanEventSourceOptionType } from 'products/titan/types';
import { STATE_THEME } from 'theme/theme';

export const TITAN_CAMPAIGN_STATE_COLOR: Record<TITAN_CAMPAIGN_STATE_ID, string> = {
  [TITAN_CAMPAIGN_STATE_ID.CREATED]: STATE_THEME.created,
  [TITAN_CAMPAIGN_STATE_ID.GATHERING_DATA]: STATE_THEME.gathering_data,
  [TITAN_CAMPAIGN_STATE_ID.MODEL_TRAINING]: STATE_THEME.model_training,
  [TITAN_CAMPAIGN_STATE_ID.SETTING_UP]: STATE_THEME.setting_up,
  [TITAN_CAMPAIGN_STATE_ID.LIVE_PENDING]: STATE_THEME.live_pending,
  [TITAN_CAMPAIGN_STATE_ID.LIVE]: STATE_THEME.live,
  [TITAN_CAMPAIGN_STATE_ID.UNHEALTHY]: STATE_THEME.unhealthy,
  [TITAN_CAMPAIGN_STATE_ID.OFFLINE]: STATE_THEME.offline,
  [TITAN_CAMPAIGN_STATE_ID.ARCHIVED]: STATE_THEME.archived,
};

export const TITAN_CAMPAIGN_NOTE_CATEGORY_NAME: Record<TITAN_CAMPAIGN_NOTE_CATEGORY_ID, string> = {
  [TITAN_CAMPAIGN_NOTE_CATEGORY_ID.GENERAL]: 'General',
  [TITAN_CAMPAIGN_NOTE_CATEGORY_ID.OTHER]: 'Other',
  [TITAN_CAMPAIGN_NOTE_CATEGORY_ID.PERFORMANCE]: 'Performance',
  [TITAN_CAMPAIGN_NOTE_CATEGORY_ID.PACING]: 'Pacing',
  [TITAN_CAMPAIGN_NOTE_CATEGORY_ID.SYSTEM]: 'System',
};

export const TITAN_EVENT_SOURCE_TYPE_NAME: Record<TITAN_EVENT_SOURCE_TYPE_ID, string> = {
  [TITAN_EVENT_SOURCE_TYPE_ID.UNIVERSAL_PIXEL]: 'Universal Pixel',
  [TITAN_EVENT_SOURCE_TYPE_ID.INDIVIDUAL_PIXEL]: 'Individual Pixel',
  [TITAN_EVENT_SOURCE_TYPE_ID.CRM]: 'CRM Event',
  [TITAN_EVENT_SOURCE_TYPE_ID.LOCATION]: 'Location Event',
  [TITAN_EVENT_SOURCE_TYPE_ID.AUDIENCE]: 'Audience Event',
  [TITAN_EVENT_SOURCE_TYPE_ID.VIRTUAL_EVENT]: 'Virtual Event',
  [TITAN_EVENT_SOURCE_TYPE_ID.CPG]: 'CPG Event',
};

export const TITAN_EVENT_SOURCE_TYPE_PATH: Record<TITAN_EVENT_SOURCE_TYPE_ID, string> = {
  [TITAN_EVENT_SOURCE_TYPE_ID.UNIVERSAL_PIXEL]: 'universal-pixels',
  [TITAN_EVENT_SOURCE_TYPE_ID.INDIVIDUAL_PIXEL]: 'individual-pixels',
  [TITAN_EVENT_SOURCE_TYPE_ID.CRM]: 'crm-events',
  [TITAN_EVENT_SOURCE_TYPE_ID.LOCATION]: 'location-events',
  [TITAN_EVENT_SOURCE_TYPE_ID.AUDIENCE]: 'audience-events',
  [TITAN_EVENT_SOURCE_TYPE_ID.VIRTUAL_EVENT]: 'virtual-events',
  [TITAN_EVENT_SOURCE_TYPE_ID.CPG]: 'cpg-events',
};

export const TITAN_EVENT_SOURCE_STATE_COLOR: Record<TITAN_EVENT_SOURCE_STATE_ID, string> = {
  [TITAN_EVENT_SOURCE_STATE_ID.CONFIGURING]: STATE_THEME.configuring,
  [TITAN_EVENT_SOURCE_STATE_ID.TESTING]: STATE_THEME.testing,
  [TITAN_EVENT_SOURCE_STATE_ID.ONLINE]: STATE_THEME.online,
  [TITAN_EVENT_SOURCE_STATE_ID.UNHEALTHY]: STATE_THEME.unhealthy,
  [TITAN_EVENT_SOURCE_STATE_ID.OFFLINE]: STATE_THEME.offline,
  [TITAN_EVENT_SOURCE_STATE_ID.ARCHIVED]: STATE_THEME.archived,
};

export const titan_campaign_options: TitanCampaignOptionType[] = [
  {
    label: 'Edit',
    id: TITAN_CAMPAIGN_OPTION_ID.EDIT,
  },
  {
    label: 'Activate',
    id: TITAN_CAMPAIGN_OPTION_ID.TURN_ACTIVE,
  },
  {
    label: 'Turn Offline',
    id: TITAN_CAMPAIGN_OPTION_ID.TURN_OFFLINE,
  },
  // {
  //   label: 'Edit Team',
  //   id: TITAN_CAMPAIGN_OPTION_ID.EDIT_TEAM,
  // },
];

export const titan_event_source_options: TitanEventSourceOptionType[] = [
  {
    label: 'Edit',
    id: TITAN_EVENT_SOURCE_OPTION_ID.EDIT,
  },
  {
    label: 'Turn Online',
    id: TITAN_EVENT_SOURCE_OPTION_ID.TURN_ONLINE,
  },
  {
    label: 'Turn Offline',
    id: TITAN_EVENT_SOURCE_OPTION_ID.TURN_OFFLINE,
  },
];

export const TITAN_DELIVERY_TYPE_NAME: Record<TITAN_DELIVERY_TYPE_ID, string> = {
  [TITAN_DELIVERY_TYPE_ID.SLACK]: 'Slack',
  [TITAN_DELIVERY_TYPE_ID.EMAIL]: 'Email',
};

export const TITAN_DELIVERY_SOURCE_NAME: Record<TITAN_DELIVERY_SOURCE_ID, string> = {
  [TITAN_DELIVERY_SOURCE_ID.DSP]: 'DSP',
  [TITAN_DELIVERY_SOURCE_ID.SSP]: 'SSP',
  [TITAN_DELIVERY_SOURCE_ID.ADSERVER]: 'Adserver',
  [TITAN_DELIVERY_SOURCE_ID.INTERNAL]: 'Internal',
  [TITAN_DELIVERY_SOURCE_ID.EXTERNAL]: 'External',
};
