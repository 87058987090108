export enum LIBRA_DEAL_STATE_ID {
  CREATED = 1,
  CONFIGURING = 2,
  INACTIVE = 3,
  LIVE_PENDING = 4,
  LIVE = 5,
  OFFLINE = 6,
  UNHEALTHY = 7,
  ARCHIVED = 91,
  FAILED = 99,
}

export enum LIBRA_DOMAIN_LIST_UPLOAD_STATE {
  PENDING = 1,
  SUCCEEDED = 4,
  FAILED = 99,
}

export enum LIBRA_DEAL_ACTION_OPTION_ID {
  EDIT = 1,
  ACTIVATE = 2,
  DEACTIVATE = 3,
  ARCHIVE = 4,
}
