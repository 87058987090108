import React, { useEffect } from 'react';
import { Button } from '@cognitiv/cassiopeia-ui';
import { Page } from 'components/layout/page/Page';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateSettings } from 'ducks/settings/slices';
import { useNavigate } from 'react-router-dom';

import cn from 'products/public/pages/error/ErrorPage.module.scss';
import { selectSettings } from 'ducks/settings/selectors';
import { Navigation } from 'components/layout/navigation/Navigation';
import { useTheme } from 'hooks/useTheme';
import { Breadcrumb } from 'components/layout/navigation/Breadcrumb';

const { PUBLIC_URL } = process.env;

export default ({ page, path }: { page: string; path: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { loading } = useAppSelector(selectSettings);

  useEffect(() => {
    if (loading) {
      dispatch(updateSettings({ loading: false }));
    }
  }, [dispatch, loading]);

  return (
    <>
      <Navigation>
        <Breadcrumb label="404" />
      </Navigation>
      <Page>
        <div className={cn.content}>
          <div className={cn.box}>
            <img src={`${PUBLIC_URL}/404-${theme.theme_variant}.svg`} alt="Cognitiv 404 Error" height={120} />
            <p>
              Oops, looks like you are stranded!
              <br />
              The page you are trying to access doesn't exist.
              <br />
              Please navigate your way back to a working page.
            </p>
            <Button min_width="160px" onClick={() => navigate(path)}>
              {page}
            </Button>
          </div>
        </div>
      </Page>
    </>
  );
};
