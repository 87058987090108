import { VALIDATE_TITAN_CREATIVE_VIEW } from 'products/titan/modals/bulk-validate-titan-creative/enums';
import { TITAN_BULK_MANAGE_CREATIVE_VIEW, TitanModals } from 'products/titan/modals/types';
import { TITAN_CREATIVE_TYPE_ID } from 'products/titan/operators/creative-types-list/enums';
import { TITAN_GENERATE_TYPE_ID } from 'products/titan/pages/creatives/enums';

export const titan_modals: TitanModals = {
  manage_titan_advertiser: { is_open: false },
  manage_titan_partner: { is_open: false },
  manage_titan_universal_pixel: { is_open: false },
  manage_titan_individual_pixel: { is_open: false },
  manage_titan_location_event: { is_open: false },
  manage_titan_cpg_event: { is_open: false },
  manage_titan_virtual_event_source: { is_open: false },
  manage_titan_virtual_event_source_note: { is_open: false },
  update_titan_event_source_note: { is_open: false },
  manage_titan_campaign_note: {
    is_open: false,
    default_campaign_note_category_id: 0,
    default_campaign_note_category_name: '',
    campaign_id: 0,
    campaign_name: '',
  },
  manage_titan_campaign_team: {
    is_open: false,
  },
  select_titan_salesforce_opportunity: { is_open: false, product_type_id: 0 },
  view_s3_objects: { is_open: false },
  manage_titan_notification: { is_open: false },
  bulk_validate_titan_creative: { is_open: false, modal_view: VALIDATE_TITAN_CREATIVE_VIEW.BULK_VALIDATE_CONFRIM },
  manage_titan_creative: { is_open: false, creative_type_id: TITAN_CREATIVE_TYPE_ID.DISPLAY },
  validate_titan_creative: { is_open: false },
  bulk_manage_titan_creatives: { is_open: false, advertiser_id: null, advertiser_name: '', view: TITAN_BULK_MANAGE_CREATIVE_VIEW.CREATE },
  generate_titan_template: { is_open: false, advertiser_id: null, advertiser_name: '', titan_generate_type: TITAN_GENERATE_TYPE_ID.EMPTY_TEMPLATE },
  create_creative_message: { is_open: false, advertiser_id: null, advertiser_name: '' },
};

export const CENTUARI_ATTRIBUTE_ID = 1;
