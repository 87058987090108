import { TitanCreativeAdServer } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCreativeAdServer[] = [];

export const titanTitanCreativeAdServersListSlice = createSlice({
  name: SLICE.TITAN_CREATIVE_AD_SERVERS_LIST,
  initialState,
  reducers: {
    clearTitanCreativeAdServersList: () => {
      return initialState;
    },
    setTitanCreativeAdServersList: (_, action: PayloadAction<TitanCreativeAdServer[]>) => action.payload,
  },
});

export const { clearTitanCreativeAdServersList, setTitanCreativeAdServersList } = titanTitanCreativeAdServersListSlice.actions;

export default titanTitanCreativeAdServersListSlice.reducer;
