import { TitanVirtualEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanVirtualEventSource[] = [];

export const titanVirtualEventSourcesSlice = createSlice({
  name: SLICE.TITAN_VIRTUAL_EVENT_SOURCES,
  initialState,
  reducers: {
    clearTitanVirtualEventSources: () => initialState,
    setTitanVirtualEventSources: (_, action: PayloadAction<TitanVirtualEventSource[]>) => action.payload,
  },
});

export const { clearTitanVirtualEventSources, setTitanVirtualEventSources } = titanVirtualEventSourcesSlice.actions;

export default titanVirtualEventSourcesSlice.reducer;
