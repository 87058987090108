import { TitanEventSourceCategory } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourceCategory[] = [];

export const titanEventSourceCategoriesListSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE_CATEGORIES_LIST,
  initialState,
  reducers: {
    clearTitanEventSourceCategoriesList: () => {
      return initialState;
    },
    setTitanEventSourceCategoriesList: (_, action: PayloadAction<TitanEventSourceCategory[]>) => action.payload,
  },
});

export const { clearTitanEventSourceCategoriesList, setTitanEventSourceCategoriesList } = titanEventSourceCategoriesListSlice.actions;

export default titanEventSourceCategoriesListSlice.reducer;
