import { TitanPage } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanPage[] = [];

export const titanPagesSlice = createSlice({
  name: SLICE.TITAN_PAGES,
  initialState,
  reducers: {
    clearTitanPages: () => initialState,
    setTitanPages: (_, action: PayloadAction<TitanPage[]>) => action.payload,
  },
});

export const { clearTitanPages, setTitanPages } = titanPagesSlice.actions;

export default titanPagesSlice.reducer;
