import { HyperionReportSchedule } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { default_hyperion_report_schedule } from 'products/hyperion/operators/report-schedule/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

export const initialState: HyperionReportSchedule = {
  ...default_hyperion_report_schedule,
};

export const hyperionReportScheduleSlice = createSlice({
  name: SLICE.HYPERION_REPORT_SCHEDULE,
  initialState,
  reducers: {
    clearHyperionReportSchedule: () => {
      return initialState;
    },
    setHyperionReportSchedule(_, action: PayloadAction<HyperionReportSchedule>) {
      return action.payload;
    },
  },
});

export const { clearHyperionReportSchedule, setHyperionReportSchedule } = hyperionReportScheduleSlice.actions;

export default hyperionReportScheduleSlice.reducer;
