import { HyperionEventSourcePerformanceSummary } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_event_source_performance_summary_default } from 'products/hyperion/operators/event-source-performance-summary/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionEventSourcePerformanceSummary = hyperion_event_source_performance_summary_default;

export const hyperionEventSourcePerformanceSummarySlice = createSlice({
  name: SLICE.HYPERION_EVENT_SOURCE_PERFORMANCE_SUMMARY,
  initialState,
  reducers: {
    clearHyperionEventSourcePerformanceSummary: () => initialState,
    setHyperionEventSourcePerformanceSummary: (_, action: PayloadAction<HyperionEventSourcePerformanceSummary>) => action.payload,
  },
});

export const { clearHyperionEventSourcePerformanceSummary, setHyperionEventSourcePerformanceSummary } =
  hyperionEventSourcePerformanceSummarySlice.actions;

export default hyperionEventSourcePerformanceSummarySlice.reducer;
