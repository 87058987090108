import { TitanEventSourceType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourceType[] = [];

export const titanEventSourceTypesListSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanEventSourceTypesList: () => {
      return initialState;
    },
    setTitanEventSourceTypesList: (_, action: PayloadAction<TitanEventSourceType[]>) => action.payload,
  },
});

export const { clearTitanEventSourceTypesList, setTitanEventSourceTypesList } = titanEventSourceTypesListSlice.actions;

export default titanEventSourceTypesListSlice.reducer;
