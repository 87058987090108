import { TitanEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSource[] = [];

export const titanEventSourcesPerformanceSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCES_PERFORMANCE,
  initialState,
  reducers: {
    clearTitanEventSourcesPerformance: () => initialState,
    setTitanEventSourcesPerformance: (_, action: PayloadAction<TitanEventSource[]>) => action.payload,
  },
});

export const { clearTitanEventSourcesPerformance, setTitanEventSourcesPerformance } = titanEventSourcesPerformanceSlice.actions;

export default titanEventSourcesPerformanceSlice.reducer;
