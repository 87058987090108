import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useScrollView = ({ active, hovering }: { active: boolean; hovering: boolean }): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'instant' as ScrollBehavior, block: 'center' });
    }
  }, []);

  useEffect(() => {
    if (active && !hovering) {
      scrollIntoView();
    }
  }, [active, scrollIntoView, hovering]);

  return ref;
};
