import { TitanEventSourceAttribute } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourceAttribute[] = [];

export const titanEventSourceAttributesSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCES_ATTRIBUTES,
  initialState,
  reducers: {
    clearTitanEventSourceAttributes: () => initialState,
    setTitanEventSourceAttributes: (_, action: PayloadAction<TitanEventSourceAttribute[]>) => action.payload,
  },
});

export const { clearTitanEventSourceAttributes, setTitanEventSourceAttributes } = titanEventSourceAttributesSlice.actions;

export default titanEventSourceAttributesSlice.reducer;
