import { EMPTY_ARRAY, EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { GlobalSearchContextProps } from 'modals/search/context/types';

export const modals_search_initial_state: GlobalSearchContextProps = {
  search: '',
  selected: '',
  hovering: true,
  recently_viewed_pages: EMPTY_ARRAY,
  navigation_pages: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
  onKeyDown: EMPTY_FUNCTION,
  setIndex: EMPTY_FUNCTION,
  setHovering: EMPTY_FUNCTION,
  setSearch: EMPTY_FUNCTION,
  onClose: EMPTY_FUNCTION,
  onClick: EMPTY_FUNCTION,
};
