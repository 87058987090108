import { PolluxFilter, PolluxFilterEquation } from '@cognitiv/galaxy-api';

export const pollux_filter_equation_default: PolluxFilterEquation = {
  filter_equation_id: 0,
  category_id: 0,
  category_name: '',
  equation_operator_type_id: 0,
  equation_operator_type_name: '',
  min_required_values: 0,
  max_required_values: 0,
  values: [],
  uuid: '',
};

export const pollux_filter_default: PolluxFilter = {
  filter_id: 0,
  filter_name: '',
  advertiser_id: null,
  advertiser_name: '',
  filter_version_id: 0,
  filter_equations: [],
  testing_state_id: 0,
  testing_state_name: '',
  apply_default_global: false,
  apply_default_organization: false,
  apply_default_advertiser: false,
  created_by: '',
  created_date: '',
  is_active: false,
  revision_id: 0,
  uuid: '0',
};
