import { PolluxFilter } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pollux_filter_default } from 'products/pollux/operators/filter/defaults';
import { SLICE } from 'products/pollux/operators/slice';

const initialState: PolluxFilter = { ...pollux_filter_default };

export const PolluxFilterSlice = createSlice({
  name: SLICE.POLLUX_FILTER,
  initialState,
  reducers: {
    clearPolluxFilter: () => initialState,
    setPolluxFilter: (_, action: PayloadAction<PolluxFilter>) => action.payload,
  },
});

export const { clearPolluxFilter, setPolluxFilter } = PolluxFilterSlice.actions;

export default PolluxFilterSlice.reducer;
