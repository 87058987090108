import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '@cognitiv/cassiopeia-ui';
import { mergeSearchQuery } from '@cognitiv/cicada-ui';
import { LibraDomainList, useClientContext } from '@cognitiv/galaxy-api';
import axios, { AxiosResponse } from 'axios';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { LIBRA_DOMAIN_LIST_UPLOAD_STATE } from 'products/libra/enums';
import { DomainListForm } from 'products/libra/modals/manage-libra-domain-list/components/DomainListForm';
import { DomainListProcessing } from 'products/libra/modals/manage-libra-domain-list/components/DomainListProcessing';
import { configuration_default } from 'products/libra/modals/manage-libra-domain-list/defaults';
import { LibraDomainListConfiguration } from 'products/libra/modals/manage-libra-domain-list/types';
import { libra_domain_list_default } from 'products/libra/operators/domain-list/defaults';
import { selectLibraDomainList } from 'products/libra/operators/domain-list/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

export const ManageLibraDomainList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const libra_domain_list = useAppSelector(selectLibraDomainList);
  const { Libra, handleError } = useClientContext();
  const [form, setForm] = useState<LibraDomainList>({ ...libra_domain_list_default });
  const [configuration, setConfiguration] = useState<LibraDomainListConfiguration>({ ...configuration_default });

  const { is_open } = useAppSelector((state) => selectModal(state, 'manage_libra_domain_list'));

  useEffect(() => {
    setForm({ ...libra_domain_list });
  }, [libra_domain_list]);

  const onChangeDomainList = useCallback((item: Partial<LibraDomainList>) => {
    setForm((prev) => ({ ...prev, ...item }));
  }, []);

  const onChangeConfiguration = useCallback((item: Partial<LibraDomainListConfiguration>) => {
    setConfiguration((prev) => ({ ...prev, ...item }));
  }, []);

  const onClose = useCallback(() => {
    setConfiguration({ ...configuration_default });
    dispatch(updateModal({ manage_libra_domain_list: { is_open: false } }));
  }, [dispatch]);

  const readDomainListFile = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (!e.target) {
          reject(new Error('File target is null'));
          return;
        }
        resolve(e.target.result as ArrayBuffer);
      };

      reader.onerror = function (err) {
        reject(err);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadDomainList = useCallback(
    async (domain_list_id: number, host: string, payload: ArrayBuffer) => {
      // DEV NOTE: axios set default headers on all requests
      axios.defaults.headers = {};
      await axios({
        method: 'put',
        url: host,
        data: payload,
      }).finally(async () => {
        await Libra.finishLibraDomainListUpload(domain_list_id);
      });
    },
    [Libra],
  );

  const processDomainList = useCallback(
    async (domain_list_id: number, presigned_url: string) => {
      if (configuration.files.length === 0) throw new Error('File is missing from dropzone');
      setForm((prev) => ({ ...prev, domain_list_id }));
      setConfiguration((prev) => ({
        ...prev,
        message: 'Domain List is being processed by AWS',
        status: LIBRA_DOMAIN_LIST_UPLOAD_STATE.PENDING,
        polling: true,
        count: 0,
      }));

      const payload = await readDomainListFile(configuration.files[0]);
      await uploadDomainList(domain_list_id, presigned_url, payload);
    },
    [configuration.files, uploadDomainList],
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!configuration.files.length) return;

      try {
        const { advertiser_id, domain_list_name } = form;

        if (libra_domain_list.domain_list_id) {
          await Libra.updateLibraDomainList(libra_domain_list.domain_list_id, { domain_list_name });

          const presigned_url: AxiosResponse['data'] = await Libra.startLibraDomainListUpload(libra_domain_list.domain_list_id);

          await processDomainList(libra_domain_list.domain_list_id, presigned_url);
        } else {
          const payload = {
            advertiser_id: advertiser_id || null,
            domain_list_name,
          };
          const domain_list_id = await Libra.createLibraDomainList(payload);

          setForm((prev) => ({ ...prev, domain_list_id }));

          const presigned_url: AxiosResponse['data'] = await Libra.startLibraDomainListUpload(domain_list_id);

          await processDomainList(domain_list_id, presigned_url);
        }
      } catch (err) {
        setConfiguration((prev) => ({ ...prev, polling: false, count: 0 }));
        handleError(err);
      }
    },
    [Libra, processDomainList, form, handleError, libra_domain_list.domain_list_id, configuration.files.length],
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | number = 0;
    if (configuration.polling) {
      interval = setInterval(() => setConfiguration((prev) => ({ ...prev, count: prev.count + 1 })), 1000);
    }
    return () => clearInterval(interval);
  }, [configuration.polling]);

  useEffect(() => {
    if (!configuration.polling) return;
    if (configuration.count % 5 === 0) {
      const validateFileUpload = async () => {
        try {
          const queries = mergeSearchQuery(search, { update: uuidv4() });
          const res = await Libra.getLibraDomainListUploadState(form.domain_list_id);

          if (res === LIBRA_DOMAIN_LIST_UPLOAD_STATE.FAILED) {
            const message: AxiosResponse['data'] = await Libra.getLibraDomainListUploadFailureReason(form.domain_list_id);
            setConfiguration((prev) => ({ ...prev, files: [], polling: false, count: 0, upload_error_message: message }));
          }

          if (res === LIBRA_DOMAIN_LIST_UPLOAD_STATE.SUCCEEDED) {
            onClose();
            setConfiguration((prev) => ({ ...prev, files: [], polling: false, count: 0 }));
            navigate(`${pathname}${queries}`);
          }
        } catch (err) {
          handleError(err);
          setConfiguration((prev) => ({ ...prev, files: [], polling: false, count: 0 }));
        }
      };
      validateFileUpload();
    }
  }, [
    dispatch,
    pathname,
    handleError,
    navigate,
    onClose,
    search,
    form.domain_list_name,
    configuration.polling,
    configuration.count,
    form.domain_list_id,
    form,
    Libra,
  ]);

  const entity_exists = !!libra_domain_list.domain_list_id;

  return (
    <Modal
      title={entity_exists && !configuration.upload_error_message ? 'Replace Domain List' : 'Upload Domain List'}
      is_open={is_open}
      width={500}
      onClose={onClose}
      identifier="manage_libra_domain_list"
    >
      {configuration.polling && <DomainListProcessing message={configuration.message} />}
      {!configuration.polling && (
        <DomainListForm
          form={form}
          files={configuration.files}
          onChangeDomainList={onChangeDomainList}
          onChangeConfiguration={onChangeConfiguration}
          onSubmit={onSubmit}
          entity_exists={entity_exists}
          upload_error_message={configuration.upload_error_message}
        />
      )}
    </Modal>
  );
};
