import { PolluxFilter } from '@cognitiv/galaxy-api';
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'products/pollux/operators/slice';

const initialState: PolluxFilter[] = [];

export const PolluxFiltersSlice = createSlice({
  name: SLICE.POLLUX_FILTERS,
  initialState,
  reducers: {
    clearPolluxFilters: () => initialState,
    setPolluxFilters: (_, action) => action.payload,
  },
});

export const { clearPolluxFilters, setPolluxFilters } = PolluxFiltersSlice.actions;

export default PolluxFiltersSlice.reducer;
