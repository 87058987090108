import { HyperionReportCascadeSorting } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_report_cascade_sortings } from 'products/hyperion/operators/report-cascade-sortings/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionReportCascadeSorting[] = [...hyperion_report_cascade_sortings];

export const hyperionReportCascadeSortingsSlice = createSlice({
  name: SLICE.HYPERION_REPORT_CASCADE_SORTINGS,
  initialState,
  reducers: {
    clearHyperionReportCascadeSortings: () => initialState,
    setHyperionReportCascadeSortings: (_, action: PayloadAction<HyperionReportCascadeSorting[]>) => action.payload,
  },
});

export const { clearHyperionReportCascadeSortings, setHyperionReportCascadeSortings } = hyperionReportCascadeSortingsSlice.actions;

export default hyperionReportCascadeSortingsSlice.reducer;
