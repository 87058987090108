import { selectSettings } from 'ducks/settings/selectors';
import { useAppSelector } from 'store/hooks';
import { colors } from 'theme/colors';

const light = {
  breadcrumb: {
    field: {
      border: { normal: 'none', hover: 'none', focus: 'none', disabled: 'none' },
      background: { normal: colors.navy[9], hover: colors.navy[8], focus: colors.navy[10], disabled: colors.navy[9] },
      color: { normal: colors.neutral[1], hover: colors.neutral[1], focus: colors.neutral[1], disabled: colors.neutral[1] },
    },
    select: { background: colors.neutral[1] },
  },
  textarea: { field: { background: { normal: colors.neutral[3] } } },
  banner: {
    banner: {
      general: {
        color: colors.neutral[10],
        description_color: colors.neutral[10],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.neutral[3],
      },
      border: {
        normal: `1px solid ${colors.neutral[3]}`,
      },
    },
  },
  banner_action: {
    banner: {
      general: {
        color: colors.neutral[10],
        description_color: colors.neutral[10],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.neutral[3],
        hover: colors.neutral[4],
      },
      border: {
        normal: `1px solid ${colors.neutral[3]}`,
        hover: `1px solid ${colors.neutral[4]}`,
      },
    },
  },
  banner_warning_action: {
    banner: {
      general: {
        color: colors.neutral[10],
        description_color: colors.neutral[10],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.red[3],
        hover: colors.red[2],
      },
      border: {
        normal: `1px solid ${colors.red[3]}`,
        hover: `1px solid ${colors.red[2]}`,
      },
    },
  },
  banner_warning_color_primary: colors.general.red,
  card_label: {
    label: { font_weight: 500 },
    font: { general: { font_size_normal: '15px' } },
    field: { color: { normal: colors.neutral[10] } },
  },
  modal_label: {
    font: { general: { font_size_normal: '17px' } },
    label: { font_weight: 500 },
    field: { color: { normal: colors.neutral[10] } },
  },
  modal_label_large: {
    font: { general: { font_size_normal: '20px' } },
    label: { font_weight: 500 },
    field: { color: { normal: colors.neutral[10] } },
  },
  information_label: {
    font: { general: { font_size_normal: '12px' } },
    label: { font_weight: 400 },
    field: { color: { normal: colors.neutral[9] } },
  },
  dropdown_menu: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
      general: { padding: '0px 12px' },
    },
    select: { background: colors.neutral[1] },
  },
  dropdown_calendar: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
      general: { padding: '0px 12px' },
    },
    select: { background: colors.neutral[1], max_height: 'max-content', padding: '0px' },
  },
  dropdown_icon: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
    },
    select: { background: colors.neutral[1] },
  },
  generic: {
    on_color: '#38444b',
    off_color: '#0d3472',
    banner_primary: colors.blue[6],
    banner_secondary: colors.neutral[10],
    disabled: colors.neutral[7],
    cell: { background: { normal: colors.neutral[1], disabled: colors.neutral[3] } },
  },
  chart: {
    color: colors.text_light.primary,
    tooltip_color: colors.text_light.primary,
    background: colors.neutral[1],
  },
  custom_tooltip_theme: {
    background: colors.neutral[1],
    color: colors.neutral[10],
    box_shadow: `0px 4px 12px 0px rgba(0, 0, 0, 0.15)`,
  },
  row_differentiate: {
    background: '#f5f5f5',
  },
};
const dark = {
  breadcrumb: {
    field: {
      border: { normal: 'none', hover: 'none', focus: 'none', disabled: 'none' },
      background: { normal: colors.platinum[10], hover: colors.platinum[9], focus: colors.platinum[9], disabled: colors.platinum[10] },
      color: { normal: colors.neutral[1], hover: colors.neutral[1], focus: colors.neutral[1], disabled: colors.neutral[1] },
    },
    select: { background: colors.neutral[11] },
  },
  textarea: { field: { background: { normal: colors.platinum[9] } } },
  banner: {
    banner: {
      general: {
        color: colors.neutral[3],
        description_color: colors.neutral[3],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.platinum[9],
      },
      border: {
        normal: `1px solid ${colors.platinum[9]}`,
      },
    },
  },
  banner_action: {
    banner: {
      general: {
        color: colors.neutral[3],
        description_color: colors.neutral[3],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.platinum[9],
        hover: colors.platinum[8],
      },
      border: {
        normal: `1px solid ${colors.platinum[9]}`,
        hover: `1px solid ${colors.platinum[8]}`,
      },
    },
  },
  banner_warning_action: {
    banner: {
      general: {
        color: colors.neutral[3],
        description_color: colors.neutral[3],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.red[7],
        hover: colors.red[6],
      },
      border: {
        normal: `1px solid ${colors.red[7]}`,
        hover: `1px solid ${colors.red[6]}`,
      },
    },
  },
  banner_warning_color_primary: colors.neutral[3],
  card_label: {
    label: { font_weight: 500 },
    font: { general: { font_size_normal: '15px' } },
    field: { color: { normal: colors.neutral[3] } },
  },
  modal_label: {
    font: { general: { font_size_normal: '17px' } },
    label: { font_weight: 500 },
    field: { color: { normal: colors.neutral[3] } },
  },
  modal_label_large: {
    font: { general: { font_size_normal: '20px' } },
    label: { font_weight: 500 },
    field: { color: { normal: colors.neutral[3] } },
  },
  information_label: {
    font: { general: { font_size_normal: '12px' } },
    label: { font_weight: 400 },
    field: { color: { normal: colors.neutral[5] } },
  },
  dropdown_menu: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
      general: { padding: '0px 12px' },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
    },
    select: { background: colors.neutral[10] },
  },
  dropdown_calendar: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
      general: { padding: '0px 12px' },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
    },
    select: { background: colors.neutral[10], max_height: 'max-content', padding: '0px' },
  },
  dropdown_icon: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
    },
    select: { background: colors.neutral[10] },
  },
  generic: {
    on_color: '#38444b',
    off_color: '#0d3472',
    banner_primary: colors.blue[6],
    banner_secondary: colors.neutral[1],
    disabled: colors.platinum[5],
    cell: { background: { normal: colors.neutral[10], disabled: colors.platinum[10] } },
  },
  chart: {
    color: colors.text_dark.primary,
    tooltip_color: colors.text_dark.primary,
    background: colors.platinum[9],
  },
  custom_tooltip_theme: { background: colors.neutral[10], color: colors.neutral[1], box_shadow: `0px 4px 4px 0px rgba(255, 255, 255, 0.04)` },
  row_differentiate: {
    background: '#38444B',
  },
};
export const useTheme = () => {
  const { theme } = useAppSelector(selectSettings);

  return { theme: theme === 'light' ? light : dark, theme_variant: theme };
};
