import { HyperionInboundReport } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionInboundReport[] = [];

export const hyperionInboundReportsSlice = createSlice({
  name: SLICE.HYPERION_INBOUND_REPORTS,
  initialState,
  reducers: {
    clearHyperionInboundReports: () => initialState,
    setHyperionInboundReports: (_, action: PayloadAction<HyperionInboundReport[]>) => action.payload,
  },
});

export const { clearHyperionInboundReports, setHyperionInboundReports } = hyperionInboundReportsSlice.actions;

export default hyperionInboundReportsSlice.reducer;
