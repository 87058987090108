import { TitanPageType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanPageType[] = [];

export const titanPageTypesListSlice = createSlice({
  name: SLICE.TITAN_PAGE_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanPageTypesList: () => {
      return initialState;
    },
    setTitanPageTypesList: (_, action: PayloadAction<TitanPageType[]>) => action.payload,
  },
});

export const { clearTitanPageTypesList, setTitanPageTypesList } = titanPageTypesListSlice.actions;

export default titanPageTypesListSlice.reducer;
