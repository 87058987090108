import React, { FC, PropsWithChildren } from 'react';

import cn from 'components/layout/page/Page.module.scss';

export const Page: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={cn.page}>
      <div className={cn.wrapper}>{children}</div>
    </div>
  );
};
