import React, { createContext, useContext, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@cognitiv/cassiopeia-ui';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import cn from 'components/menu/components/Components.module.scss';
import { MenuFixed } from 'components/menu/components/MenuFixed';
import { menu_theme } from 'components/menu/components/theme';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { MainMenuPageProps, MenuGroupContext } from 'components/menu/types';

const Context = createContext<MenuGroupContext>({
  is_expanded: false,
  label: '',
  selected: false,
});

export const useMenuGroupContext = () => useContext(Context);

export const MenuGroup = ({ icon, label, children, selected, active, to }: MainMenuPageProps) => {
  const ref = useRef(null);
  const { is_menu_open, handleExpand } = useMenuContext();

  const value = useMemo(() => ({ label, selected }), [label, selected]);

  return (
    <div className={cn.wrapper} ref={ref}>
      <Link to={to} onClick={handleExpand} className={cn.link}>
        <div className={classNames(cn.group, { [cn.open]: is_menu_open, [cn.selected]: selected })}>
          <Icon icon={icon} theme={menu_theme.large} />
          <p className={cn.label}>{label}</p>
          {is_menu_open && <Icon icon={faCaretRight} theme={menu_theme.medium} />}
        </div>
      </Link>
      <Context.Provider value={value}>
        <MenuFixed to={to} selected={active}>
          {children}
        </MenuFixed>
      </Context.Provider>
    </div>
  );
};
