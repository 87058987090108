import {
  faBookUser,
  faBriefcase,
  faCircleNodes,
  faDisplayCode,
  faPeopleGroup,
  faSquareTerminal,
  faTableLayout,
} from '@fortawesome/pro-regular-svg-icons';
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { PAGE } from 'hooks/useMenu';

export const getIcon = (url: string) => {
  switch (true) {
    case url.includes(PAGE.VIRGO):
      return faPeopleGroup;
    case url.includes(PAGE.ORION):
      return faSquareTerminal;
    case url.includes(PAGE.REPORTING):
      return faTableLayout;
    case url.includes(PAGE.EVENT_SOURCES):
      return faDisplayCode;
    case url.includes(PAGE.PARTNERS):
      return faBriefcase;
    case url.includes(PAGE.ADVERTISERS):
      return faBookUser;
    case url.includes(PAGE.DSP_IDENTIFIERS):
      return faCircleNodes;
    default:
      return faGrid2;
  }
};
