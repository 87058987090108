import { TitanCampaignRolePerson } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignRolePerson[] = [];

export const titanCampaignRolePersonsSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_ROLE_PERSONS,
  initialState,
  reducers: {
    clearTitanCampaignRolePersons: () => {
      return initialState;
    },
    setTitanCampaignRolePersons: (_, action: PayloadAction<TitanCampaignRolePerson[]>) => action.payload,
  },
});

export const { clearTitanCampaignRolePersons, setTitanCampaignRolePersons } = titanCampaignRolePersonsSlice.actions;

export default titanCampaignRolePersonsSlice.reducer;
