import { HyperionImportConfigUnjoinedRow } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionImportConfigUnjoinedRow[] = [];

export const hyperionImportConfigUnjoinedRowsSlice = createSlice({
  name: SLICE.HYPERION_IMPORT_CONFIG_UNJOINED_ROWS,
  initialState,
  reducers: {
    clearHyperionImportConfigUnjoinedRows: () => initialState,
    setHyperionImportConfigUnjoinedRows: (_, action: PayloadAction<HyperionImportConfigUnjoinedRow[]>) => action.payload,
  },
});

export const { clearHyperionImportConfigUnjoinedRows, setHyperionImportConfigUnjoinedRows } = hyperionImportConfigUnjoinedRowsSlice.actions;

export default hyperionImportConfigUnjoinedRowsSlice.reducer;
