import { OrionSegment } from '@cognitiv/galaxy-api';
import { EMBEDDING_VERSION } from 'products/orion/pages/segment/constants';
import { ORION_PREVIEW_SORT, ORION_PREVIEW_SORT_NAME } from 'products/orion/pages/segment/enums';

export const orion_segment_default: OrionSegment = {
  segment_id: 0,
  segment_name: '',
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  segment_type_id: 1,
  segment_type_name: 'Relevance',
  state_id: 0,
  state_name: '',
  created_date: '',
  created_by: '',
  current_segment_version_id: null,
  last_updated_date: '',
  url_count: null,
  created_by_user_id: 0,
  embedding_filter_id: null,
  embedding_name: 'GPT',
  embedding_id: EMBEDDING_VERSION,
  weighted_relevance: 0,
  avails: 0,
  deal_count: 0,
  spend_value: null,
  preview_order_by_id: ORION_PREVIEW_SORT.RELEVANT,
  preview_order_by_name: ORION_PREVIEW_SORT_NAME.MOST_RELEVANT,
  preview_order_ascending: false,
  deal_count_formatted: '',
  url_count_formatted: '',
  weighted_relevance_formatted: '',
  avails_formatted: '',
  spend_value_formatted: '',
  creative_type_id: null,
  creative_type_name: '',
  device_type_id: null,
  device_type_name: '',
  data_center_id: 0,
  data_center_name: '',
  uuid: '',
};
