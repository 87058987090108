import { TitanEventSourcePropertyType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourcePropertyType[] = [];

export const titanEventSourcePropertyTypesListSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE_PROPERTY_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanEventSourcePropertyTypesList: () => {
      return initialState;
    },
    setTitanEventSourcePropertyTypesList: (_, action: PayloadAction<TitanEventSourcePropertyType[]>) => action.payload,
  },
});

export const { clearTitanEventSourcePropertyTypesList, setTitanEventSourcePropertyTypesList } = titanEventSourcePropertyTypesListSlice.actions;

export default titanEventSourcePropertyTypesListSlice.reducer;
