import { HyperionReportSchedule } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionReportSchedule[] = [];

export const hyperionReportSchedulesSlice = createSlice({
  name: SLICE.HYPERION_REPORT_SCHEDULES,
  initialState,
  reducers: {
    clearHyperionReportSchedules: () => initialState,
    setHyperionReportSchedules: (_, action: PayloadAction<HyperionReportSchedule[]>) => action.payload,
  },
});

export const { clearHyperionReportSchedules, setHyperionReportSchedules } = hyperionReportSchedulesSlice.actions;

export default hyperionReportSchedulesSlice.reducer;
