import { TitanCreativeMessage } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCreativeMessage[] = [];

export const titanCreativeMessagesSlice = createSlice({
  name: SLICE.TITAN_CREATIVE_MESSAGES,
  initialState,
  reducers: {
    clearTitanCreativeMessages: () => {
      return initialState;
    },
    setTitanCreativeMessages: (_, action: PayloadAction<TitanCreativeMessage[]>) => action.payload,
  },
});

export const { clearTitanCreativeMessages, setTitanCreativeMessages } = titanCreativeMessagesSlice.actions;

export default titanCreativeMessagesSlice.reducer;
