import { TitanProductType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanProductType[] = [];

export const titanProductTypesListSlice = createSlice({
  name: SLICE.TITAN_PRODUCT_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanProductTypesList: () => initialState,
    setTitanProductTypesList: (_, action: PayloadAction<TitanProductType[]>) => action.payload,
  },
});

export const { clearTitanProductTypesList, setTitanProductTypesList } = titanProductTypesListSlice.actions;

export default titanProductTypesListSlice.reducer;
