import { HyperionImportHealthStatusHistory } from '@cognitiv/galaxy-api';

export const hyperion_import_status_history_default: HyperionImportHealthStatusHistory = {
  start_date_utc: '',
  start_date_formatted: '',
  end_date_utc: '',
  end_date_formatted: '',
  data: [],
  uuid: '',
};
