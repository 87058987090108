import { LibraDomainList } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { libra_domain_list_default } from 'products/libra/operators/domain-list/defaults';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraDomainList = { ...libra_domain_list_default };

export const libraDomainListSlice = createSlice({
  name: SLICE.LIBRA_DOMAIN_LIST,
  initialState,
  reducers: {
    clearLibraDomainList: () => libra_domain_list_default,
    setLibraDomainList: (_, action: PayloadAction<LibraDomainList>) => action.payload,
  },
});

export const { clearLibraDomainList, setLibraDomainList } = libraDomainListSlice.actions;

export default libraDomainListSlice.reducer;
