import { HyperionOutputType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionOutputType[] = [];

export const hyperionOutputTypesListSlice = createSlice({
  name: SLICE.HYPERION_OUTPUT_TYPES_LIST,
  initialState,
  reducers: {
    clearHyperionOutputTypesList: () => {
      return initialState;
    },
    setHyperionOutputTypesList: (_, action: PayloadAction<HyperionOutputType[]>) => action.payload,
  },
});

export const { clearHyperionOutputTypesList, setHyperionOutputTypesList } = hyperionOutputTypesListSlice.actions;

export default hyperionOutputTypesListSlice.reducer;
