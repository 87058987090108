import { createSlice } from '@reduxjs/toolkit';
import { Settings } from 'ducks/settings/types';
import { SLICE } from 'ducks/slice';

const initialState: Settings = {
  sw_registration: { waiting: false },
  orion: false,
  virgo: false,
  titan: false,
  hyperion: false,
  libra: false,
  luna: false,
  core: false,
  pollux: false,
  loading: false,
  is_menu_open: false,
  is_application_menu_open: false,
  loading_creative_message: false,
  theme: 'light',
};
export const settingsSlice = createSlice({
  name: SLICE.SETTINGS,
  initialState,
  reducers: {
    updateSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
    triggerOrionLookups: (state) => {
      return { ...state, orion: true };
    },
    triggerVirgoLookups: (state) => {
      return { ...state, virgo: true };
    },
    triggerTitanLookups: (state) => {
      return { ...state, titan: true };
    },
    triggerHyperionLookups: (state) => {
      return { ...state, hyperion: true };
    },
    triggerLibraLookups: (state) => {
      return { ...state, libra: true };
    },
    triggerLunaLookups: (state) => {
      return { ...state, luna: true };
    },
    triggerCoreLookups: (state) => {
      return { ...state, core: true };
    },
    triggerPolluxLookups: (state) => {
      return { ...state, pollux: true };
    },
  },
});

export const {
  updateSettings,
  triggerOrionLookups,
  triggerVirgoLookups,
  triggerTitanLookups,
  triggerHyperionLookups,
  triggerLibraLookups,
  triggerLunaLookups,
  triggerCoreLookups,
  triggerPolluxLookups,
} = settingsSlice.actions;

export default settingsSlice.reducer;
