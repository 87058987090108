import { TitanEventSourceOperatorType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourceOperatorType[] = [];

export const titanEventSourceOperatorTypesListSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE_OPERATOR_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanEventSourceOperatorTypesList: () => {
      return initialState;
    },
    setTitanEventSourceOperatorTypesList: (_, action: PayloadAction<TitanEventSourceOperatorType[]>) => action.payload,
  },
});

export const { clearTitanEventSourceOperatorTypesList, setTitanEventSourceOperatorTypesList } = titanEventSourceOperatorTypesListSlice.actions;

export default titanEventSourceOperatorTypesListSlice.reducer;
