import { HYPERION_IMPORT_CONFIG_MODAL_VIEW } from 'products/hyperion/modals/manage-hyperion-import-config/enums';
import { HyperionModals } from 'products/hyperion/modals/types';
import { HYPERION_REPORT_SAVE_OPTION_ID, HYPERION_REPORT_SCHEDULE_VIEW } from 'products/hyperion/pages/custom-report/enums';

export const hyperion_modals: HyperionModals = {
  create_hyperion_report: { is_open: false },
  manage_hyperion_column_conditions: { is_open: false },
  save_hyperion_report: { is_open: false, view: HYPERION_REPORT_SAVE_OPTION_ID.SAVE_RENAME },
  manage_hyperion_schedules: { is_open: false, view: HYPERION_REPORT_SCHEDULE_VIEW.ALL_SCHEDULES },
  confirm_hyperion_report_type: { is_open: false },
  manage_hyperion_cascade_sort: { is_open: false },
  manage_hyperion_import_config: { is_open: false, modal_view: HYPERION_IMPORT_CONFIG_MODAL_VIEW.PREVIEW, loading: false },
  manage_hyperion_importer_row_filter: { is_open: false },
  manage_hyperion_importer_expression: { is_open: false },
  view_hyperion_import_config_imported_data: { is_open: false },
  view_hyperion_import_config_status_history: { is_open: false, loading: false },
  view_hyperion_unhealthy_configs_list: { is_open: false, configs: [], date: '' },
  view_hyperion_import_config_status_issues: { is_open: false, issues: [] },
  manage_hyperion_ignore_import_config: { is_open: false },
  update_hyperion_import_config_name: { is_open: false },
};

export const REQUIRED_PLACEHOLDER = 'Required';
