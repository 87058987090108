import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { useAddToHomeScreenPrompt } from 'hooks/useAddToHomeScreenPrompt';
import { useMenu } from 'hooks/useMenu';

import { MenuContextProps } from 'components/menu/types';

const Context = createContext<MenuContextProps>({
  group: '',
  getApplicationPage: () => '',
  page: '',
  is_menu_open: false,
  is_application_menu_open: false,
  setMenu: EMPTY_FUNCTION,
  setApplicationMenu: EMPTY_FUNCTION,
  is_installed: null,
  expanded_menu_group: undefined,
  handleExpand: EMPTY_FUNCTION,
});

export const useMenuContext = () => useContext(Context);

export const MenuContext = ({ children }: { children: ReactNode }) => {
  const is_installed = useAddToHomeScreenPrompt();
  const { group, page, is_menu_open, is_application_menu_open, setMenu, setApplicationMenu, getApplicationPage, expanded_menu_group, handleExpand } =
    useMenu();

  const value = useMemo(
    () => ({
      group,
      getApplicationPage,
      is_menu_open,
      is_application_menu_open,
      setMenu,
      expanded_menu_group,
      setApplicationMenu,
      page,
      is_installed,
      handleExpand,
    }),
    [
      group,
      getApplicationPage,
      is_menu_open,
      is_application_menu_open,
      setMenu,
      setApplicationMenu,
      page,
      is_installed,
      expanded_menu_group,
      handleExpand,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
