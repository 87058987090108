import { HyperionReportSchedule } from '@cognitiv/galaxy-api';
import { HYPERION_INTERVAL_TYPE_NAME, HYPERION_OUTPUT_TYPE_NAME } from 'products/hyperion/pages/custom-report/constants';
import { HYPERION_INTERVAL_TYPE, HYPERION_OUTPUT_TYPE } from 'products/hyperion/pages/custom-report/enums';

export const default_hyperion_report_schedule: HyperionReportSchedule = {
  schedule_id: 0,
  identity_id: 0,
  report_id: 0,
  subscription_id: 0,
  start_datetime_utc: '',
  start_date_formatted: '',
  start_datetime_formatted: '',
  start_hour: 0,
  start_hour_formatted: '',
  finish_datetime_utc: null,
  finish_date_formatted: '',
  schedule_state_id: 0,
  schedule_state_name: '',
  interval_id: HYPERION_INTERVAL_TYPE.DAILY,
  interval_name: HYPERION_INTERVAL_TYPE_NAME[HYPERION_INTERVAL_TYPE.DAILY],
  interval_bitmask: '',
  next_datetime_utc: null,
  next_datetime_formatted: '',
  created_datetime_utc: '',
  created_datetime_formatted: '',
  output_type_id: HYPERION_OUTPUT_TYPE.CSV,
  output_type_name: HYPERION_OUTPUT_TYPE_NAME[HYPERION_OUTPUT_TYPE.CSV],
  delivery_destinations: [],
  uuid: '',
};
