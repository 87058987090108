import { useEffect, useState } from 'react';
import { EMPTY_ARRAY, EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { TitanPage } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { useAppSelector } from 'store/hooks';

export enum KEY {
  ENTER = 'Enter',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
}

interface UseKeyDownOptions {
  options: TitanPage[];
  onClick: (option: TitanPage) => void;
  search: string;
}

export const useKeyDown = ({ options = EMPTY_ARRAY, onClick = EMPTY_FUNCTION, search }: UseKeyDownOptions) => {
  const [index, setIndex] = useState(-1);
  const [selected, setSelected] = useState('');
  const [hovering, setHovering] = useState(true);

  const { is_open } = useAppSelector((state) => selectModal(state, 'search'));

  useEffect(() => {
    if (!is_open) {
      setIndex(-1);
      setHovering(true);
    }
  }, [is_open]);

  useEffect(() => {
    setIndex(-1);
  }, [search]);

  useEffect(() => {
    if (index === -1) {
      setSelected('');
      return;
    }

    setSelected(options[index].uuid);
  }, [index]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (options.length === 0) return;

    switch (e.key) {
      case KEY.ARROW_DOWN:
        setIndex((index + 1) % options.length);
        break;
      case KEY.ARROW_UP:
        setIndex((index + options.length - 1) % options.length);
        break;
      case KEY.ENTER: {
        const option = options[index];
        if (option) {
          onClick(option);
        }
        setIndex(-1);
      }
    }
    setHovering(false);
  };

  return [selected, hovering, onKeyDown, setIndex, setHovering] as const;
};
