import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orion_segment_types_default } from 'products/orion/operators/segment-types/defaults';
import { OrionSegmentType } from 'products/orion/operators/segment-types/types';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionSegmentType[] = orion_segment_types_default;

export const orionSegmentTypesSlice = createSlice({
  name: SLICE.ORION_SEGMENT_TYPES,
  initialState,
  reducers: {
    clearOrionSegmentTypes: () => initialState,
    setOrionSegmentTypes: (_, action: PayloadAction<OrionSegmentType[]>) => action.payload,
  },
});

export const { clearOrionSegmentTypes, setOrionSegmentTypes } = orionSegmentTypesSlice.actions;

export default orionSegmentTypesSlice.reducer;
