import { TitanNotification } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_notification_default } from 'products/titan/operators/notification/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanNotification = { ...titan_notification_default };

export const titanNotificationSlice = createSlice({
  name: SLICE.TITAN_NOTIFICATION,
  initialState,
  reducers: {
    clearTitanNotification: () => initialState,
    setTitanNotification: (_, action: PayloadAction<TitanNotification>) => action.payload,
  },
});

export const { clearTitanNotification, setTitanNotification } = titanNotificationSlice.actions;

export default titanNotificationSlice.reducer;
