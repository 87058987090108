import { HyperionReportCascadeSorting } from '@cognitiv/galaxy-api';

export const HYPERION_CASCADE_SORTING_NAME = {
  ASC: 'Ascending',
  DESC: 'Descending',
  NONE: 'None',
};

export const hyperion_report_cascade_sortings: HyperionReportCascadeSorting[] = [
  {
    col_sort_direction_name: HYPERION_CASCADE_SORTING_NAME.ASC,
    col_sort_direction: 'asc',
    uuid: 1,
  },
  {
    col_sort_direction_name: HYPERION_CASCADE_SORTING_NAME.DESC,
    col_sort_direction: 'desc',
    uuid: 2,
  },
  {
    col_sort_direction_name: HYPERION_CASCADE_SORTING_NAME.NONE,
    col_sort_direction: null,
    uuid: 3,
  },
];
