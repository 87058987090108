import { HyperionOperatorType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_operator_types } from 'products/hyperion/operators/operator-types/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionOperatorType[] = [...hyperion_operator_types];

export const hyperionOperatorTypesSlice = createSlice({
  name: SLICE.HYPERION_OPERATOR_TYPES,
  initialState,
  reducers: {
    clearHyperionOperatorTypes: () => initialState,
    setHyperionOperatorTypes: (_, action: PayloadAction<HyperionOperatorType[]>) => action.payload,
  },
});

export const { clearHyperionOperatorTypes, setHyperionOperatorTypes } = hyperionOperatorTypesSlice.actions;

export default hyperionOperatorTypesSlice.reducer;
