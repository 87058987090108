import React, { useCallback } from 'react';
import { Action, Button, Form, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { Stack } from 'modals/utility/Stack';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'modals/utility/Modal.module.scss';

export const Error = () => {
  const dispatch = useAppDispatch();
  const { name, message, stack, raw, is_open, error } = useAppSelector((state) => selectModal(state, 'error'));

  const onClose = useCallback(() => {
    dispatch(updateModal({ error: { is_open: false } }));
  }, [dispatch]);

  const onOpenStack = useCallback(() => {
    dispatch(updateModal({ stack: { is_open: true } }));
  }, [dispatch]);

  const uncaught = !error && !message;
  const more = !!stack || !!raw;
  const title = name || 'Something went wrong!';

  const formatted_message = message?.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <>
      <Modal title={title} is_open={is_open} identifier="error" width={440} onClose={onClose}>
        <Form>
          <div className={cn.body}>
            {uncaught && (
              <p className={cn.message}>Please close this popup and try accessing the page again. If the error persists please contact Cognitiv.</p>
            )}
            {error && <p className={cn.message}>{error}</p>}
            {formatted_message && <p className={cn.message}>{formatted_message}</p>}
          </div>
          <div className={cn.footer}>
            {more && (
              <Action variant="negative" onClick={onOpenStack}>
                Learn More
              </Action>
            )}
            <div className={cn.grow} />
            <Button onClick={onClose}>Ok</Button>
          </div>
        </Form>
      </Modal>
      <Stack />
    </>
  );
};
