import { HyperionEventSourcePerformanceSummary } from '@cognitiv/galaxy-api';

export const hyperion_event_source_performance_summary_default: HyperionEventSourcePerformanceSummary = {
  today_count: 0,
  today_count_formatted: '-',
  yesterday_count: 0,
  yesterday_count_formatted: '-',
  this_week_count: 0,
  this_week_count_formatted: '-',
  this_month_count: 0,
  this_month_count_formatted: '-',
  all_time_count: 0,
  all_time_count_formatted: '-',
  daily_average_count: 0,
  daily_average_count_formatted: '-',
  weekly_average_count: 0,
  weekly_average_count_formatted: '-',
  monthly_average_count: 0,
  monthly_average_count_formatted: '-',
};
