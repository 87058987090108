import { TitanVirtualEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';
import { titan_virtual_event_source_default } from 'products/titan/operators/virtual-event-source/defaults';

const initialState: TitanVirtualEventSource = { ...titan_virtual_event_source_default };

export const titanVirtualEventSourceSlice = createSlice({
  name: SLICE.TITAN_VIRTUAL_EVENT_SOURCE,
  initialState,
  reducers: {
    clearTitanVirtualEventSource: () => initialState,
    setTitanVirtualEventSource: (_, action: PayloadAction<TitanVirtualEventSource>) => action.payload,
  },
});

export const { clearTitanVirtualEventSource, setTitanVirtualEventSource } = titanVirtualEventSourceSlice.actions;

export default titanVirtualEventSourceSlice.reducer;
