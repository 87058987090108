import React from 'react';
import { Icon } from '@cognitiv/cassiopeia-ui';
import { TitanPage } from '@cognitiv/galaxy-api';
import classNames from 'classnames';
import { useSearchContext } from 'modals/search/context/SearchCommandContext';
import { useScrollView } from 'modals/search/hooks/useScrollView';
import { getIcon } from 'modals/search/utils';

import cn from 'modals/search/Search.module.scss';

export const Option = ({ page, active = false }: { page: TitanPage; active?: boolean }) => {
  const { hovering, options, setIndex, setHovering, onClick } = useSearchContext();
  const divRef = useScrollView({ active, hovering });
  const icon = getIcon(page.to);

  return (
    <div
      ref={divRef}
      className={classNames(cn.option, { [cn.active]: active })}
      onClick={() => onClick(page)}
      onMouseMove={() => {
        if (hovering) {
          setIndex(options.findIndex((e) => e.uuid === page.uuid));
        }
        setHovering(true);
      }}
    >
      <div className={cn.icon}>
        <Icon icon={icon} theme={{ icon: { color: 'inherit' } }} />
      </div>
      <p>{page.page_name}</p>
      {/* <div className={classNames(cn.icon, cn.star)}>
        <Icon icon={element.is_favorite ? faStarSolid : fsStarRegular} theme={{ icon: { color: 'inherit' } }} onClick={EMPTY_FUNCTION} />
      </div> */}
      <span className={cn.description}>{page.page_type_name}</span>
    </div>
  );
};
