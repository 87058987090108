import { TitanCampaignRole } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignRole[] = [];

export const titanCampaignRolesListSlice = createSlice({
  name: SLICE.TITAN_CREATIVE_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanCampaignRolesList: () => initialState,
    setTitanCampaignRolesList: (_, action: PayloadAction<TitanCampaignRole[]>) => action.payload,
  },
});

export const { clearTitanCampaignRolesList, setTitanCampaignRolesList } = titanCampaignRolesListSlice.actions;

export default titanCampaignRolesListSlice.reducer;
