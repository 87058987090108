import { TitanCreativeBannerSize } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCreativeBannerSize[] = [];

export const titanCreativeBannerSizesListSlice = createSlice({
  name: SLICE.TITAN_CREATIVE_BANNER_SIZES_LIST,
  initialState,
  reducers: {
    clearTitanCreativeBannerSizesList: () => {
      return initialState;
    },
    setTitanCreativeBannerSizesList: (_, action: PayloadAction<TitanCreativeBannerSize[]>) => action.payload,
  },
});

export const { clearTitanCreativeBannerSizesList, setTitanCreativeBannerSizesList } = titanCreativeBannerSizesListSlice.actions;

export default titanCreativeBannerSizesListSlice.reducer;
