import { HyperionOperatorType } from '@cognitiv/galaxy-api';

export const HYPERION_OPERATOR_NAME = {
  1: 'Greater Than',
  2: 'Less Than',
  3: 'Greater Than or Equal',
  4: 'Less Than or Equal',
  5: 'Equal',
  6: 'Not Equal',
  7: 'Between',
  8: 'In',
  9: 'Not In',
};

export const HYPERION_OPERATOR_TYPE = {
  GREATER_THAN: 1,
  LESS_THAN: 2,
  GREATER_THAN_OR_EQUAL: 3,
  LESS_THAN_OR_EQUAL: 4,
  EQUAL: 5,
  NOT_EQUAL: 6,
  BETWEEN: 7,
  IN: 8,
  NOT_IN: 9,
};

export const hyperion_operator_types: HyperionOperatorType[] = [
  {
    operator_type_name: HYPERION_OPERATOR_NAME[HYPERION_OPERATOR_TYPE.GREATER_THAN],
    operator_type_id: HYPERION_OPERATOR_TYPE.GREATER_THAN,
    uuid: 1,
  },
  {
    operator_type_name: HYPERION_OPERATOR_NAME[HYPERION_OPERATOR_TYPE.LESS_THAN],
    operator_type_id: HYPERION_OPERATOR_TYPE.LESS_THAN,
    uuid: 2,
  },
  {
    operator_type_name: HYPERION_OPERATOR_NAME[HYPERION_OPERATOR_TYPE.EQUAL],
    operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
    uuid: 3,
  },
  {
    operator_type_name: HYPERION_OPERATOR_NAME[HYPERION_OPERATOR_TYPE.NOT_EQUAL],
    operator_type_id: HYPERION_OPERATOR_TYPE.NOT_EQUAL,
    uuid: 4,
  },
  {
    operator_type_name: HYPERION_OPERATOR_NAME[HYPERION_OPERATOR_TYPE.IN],
    operator_type_id: HYPERION_OPERATOR_TYPE.IN,
    uuid: 5,
  },
  {
    operator_type_name: HYPERION_OPERATOR_NAME[HYPERION_OPERATOR_TYPE.BETWEEN],
    operator_type_id: HYPERION_OPERATOR_TYPE.BETWEEN,
    uuid: 6,
  },
];
