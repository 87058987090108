import { TitanCampaignTeamMember } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignTeamMember[] = [];

export const titanCampaignTeamSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_TEAM,
  initialState,
  reducers: {
    clearTitanCampaignTeam: () => initialState,
    setTitanCampaignTeam: (_, action: PayloadAction<TitanCampaignTeamMember[]>) => action.payload,
  },
});

export const { clearTitanCampaignTeam, setTitanCampaignTeam } = titanCampaignTeamSlice.actions;

export default titanCampaignTeamSlice.reducer;
