import { HyperionFilterTimestamp } from '@cognitiv/galaxy-api';
import { HYPERION_OPERATOR_TYPE } from 'products/hyperion/operators/operator-types/defaults';
import { uuidv4 } from 'utils/uuid';

export const hyperion_filter_timestamps: HyperionFilterTimestamp[] = [
  {
    timestamp_name: 'Custom',
    timestamp_id: 1,
    timestamp_group_name: 'Day',
    timestamp_group_id: 1,
    timestamp_value: null,
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
        operator_type_name: 'Greater Than Or Equal To',
        values: [],
      },
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
        operator_type_name: 'Less Than Or Equal To',
        values: [],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Today',
    timestamp_id: 2,
    timestamp_group_name: 'Day',
    timestamp_group_id: 1,
    timestamp_value: '%TODAY%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%TODAY%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Yesterday',
    timestamp_id: 3,
    timestamp_group_name: 'Day',
    timestamp_group_id: 1,
    timestamp_value: '%YESTERDAY%',
    option_type_id: 1,
    option_type_name: 'Break',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%YESTERDAY%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'This Month',
    timestamp_id: 9,
    timestamp_group_name: 'Month',
    timestamp_group_id: 3,
    timestamp_value: '%THIS_MONTH%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%THIS_MONTH%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Month to Date',
    timestamp_id: 9,
    timestamp_group_name: 'Month',
    timestamp_group_id: 3,
    timestamp_value: '%MONTH_TO_DATE%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%MONTH_TO_DATE%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Last Month',
    timestamp_id: 9,
    timestamp_group_name: 'Month',
    timestamp_group_id: 3,
    timestamp_value: '%LAST_MONTH%',
    option_type_id: 1,
    option_type_name: 'Break',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%LAST_MONTH%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'This Quarter',
    timestamp_id: 12,
    timestamp_group_name: 'Quarter',
    timestamp_group_id: 4,
    timestamp_value: '%THIS_QUARTER%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%THIS_QUARTER%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Quarter to Date',
    timestamp_id: 12,
    timestamp_group_name: 'Quarter',
    timestamp_group_id: 4,
    timestamp_value: '%QUARTER_TO_DATE%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%QUARTER_TO_DATE%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Last Quarter',
    timestamp_id: 12,
    timestamp_group_name: 'Quarter',
    timestamp_group_id: 4,
    timestamp_value: '%LAST_QUARTER%',
    option_type_id: 1,
    option_type_name: 'Break',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%LAST_QUARTER%'],
      },
    ],
    uuid: uuidv4(),
  },

  {
    timestamp_name: 'This Year',
    timestamp_id: 15,
    timestamp_group_name: 'Year',
    timestamp_group_id: 5,
    timestamp_value: '%THIS_YEAR%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%THIS_YEAR%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Year to Date',
    timestamp_id: 15,
    timestamp_group_name: 'Year',
    timestamp_group_id: 5,
    timestamp_value: '%YEAR_TO_DATE%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%YEAR_TO_DATE%'],
      },
    ],
    uuid: uuidv4(),
  },
  {
    timestamp_name: 'Last Year',
    timestamp_id: 15,
    timestamp_group_name: 'Year',
    timestamp_group_id: 5,
    timestamp_value: '%LAST_YEAR%',
    option_type_id: 2,
    option_type_name: 'Option',
    filters: [
      {
        field_id: 0,
        operator_type_id: HYPERION_OPERATOR_TYPE.EQUAL,
        operator_type_name: 'Equal',
        values: ['%LAST_YEAR%'],
      },
    ],
    uuid: uuidv4(),
  },
];
