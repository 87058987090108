import { TitanEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_event_source_default } from 'products/titan/operators/event-source/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSource = { ...titan_event_source_default };

export const titanEventSourceSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE,
  initialState,
  reducers: {
    clearTitanEventSource: () => initialState,
    setTitanEventSource: (_, action: PayloadAction<TitanEventSource>) => action.payload,
  },
});

export const { clearTitanEventSource, setTitanEventSource } = titanEventSourceSlice.actions;

export default titanEventSourceSlice.reducer;
