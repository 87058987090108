import React from 'react';
import { Link } from 'react-router-dom';
import { CARET, Icon, Tooltip } from '@cognitiv/cassiopeia-ui';
import classNames from 'classnames';

import cn from 'components/menu/components/Components.module.scss';
import { menu_theme } from 'components/menu/components/theme';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { MenuPageProps } from 'components/menu/types';

export const MenuPage = ({ to, icon, label, override, selected }: MenuPageProps) => {
  const { is_menu_open, handleExpand } = useMenuContext();

  return (
    <Link
      className={classNames(cn.page, { [cn.open]: is_menu_open, [cn.override]: override, [cn.selected]: selected })}
      to={to}
      onClick={handleExpand}
    >
      {icon && <Icon icon={icon} theme={menu_theme.large} />}
      <p className={cn.label}>{label}</p>
      {override && !is_menu_open && <Tooltip message={label} caret={CARET.LEFT} />}
    </Link>
  );
};
