import { TitanPartner } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_partner_default } from 'products/titan/operators/partner/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanPartner = {
  ...titan_partner_default,
};

const titanPartnerSlice = createSlice({
  name: SLICE.TITAN_PARTNER,
  initialState,
  reducers: {
    clearTitanPartner: () => {
      return initialState;
    },
    setTitanPartner(_, action: PayloadAction<TitanPartner>) {
      return action.payload;
    },
  },
});

export const { clearTitanPartner, setTitanPartner } = titanPartnerSlice.actions;

export default titanPartnerSlice.reducer;
