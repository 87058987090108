import { TitanEventSourceDataProvider } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSourceDataProvider[] = [];

export const titanEventSourceDataProvidersListSlice = createSlice({
  name: SLICE.TITAN_EVENT_SOURCE_OPERATOR_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanEventSourceDataProvidersList: () => {
      return initialState;
    },
    setTitanEventSourceDataProvidersList: (_, action: PayloadAction<TitanEventSourceDataProvider[]>) => action.payload,
  },
});

export const { clearTitanEventSourceDataProvidersList, setTitanEventSourceDataProvidersList } = titanEventSourceDataProvidersListSlice.actions;

export default titanEventSourceDataProvidersListSlice.reducer;
