export const colors = {
  general: {
    green: '#5cb85c',
    orange: '#f9a43e',
    blue: '#3e96ed',
    grey: '#d6dbdf',
    red: '#d9534f',
    purple: '#906fb1',
    pink: '#e855b6',
    black: '#1d1d1d',
    white: '#fff',
    blue_grey: '#62738d',
  },
  neutral: {
    1: '#ffffff',
    2: '#fcfcfc',
    3: '#f5f5f5',
    4: '#f0f0f0',
    5: '#d9d9d9',
    6: '#bfbfbf',
    7: '#8c8c8c',
    8: '#595959',
    9: '#454545',
    10: '#22272b',
    11: '#1c2126',
    12: '#161a1d',
  },
  pink: {
    1: '#fde9f1',
    2: '#f9cade',
    3: '#f5a0c4',
    4: '#f073a8',
    5: '#eb498e',
    6: '#e72175',
    7: '#c41c63',
    8: '#a41753',
    9: '#841343',
    10: '#680f35',
    11: '#7e3856',
  },
  teal: {
    1: '#eafbf9',
    2: '#cdf6f1',
    3: '#a5efe5',
    4: '#7be7d9',
    5: '#54e0ce',
    6: '#2ed9c3',
    7: '#27b8a6',
    8: '#219a8a',
    9: '#1a7c6f',
    10: '#156258',
  },
  yellow: {
    1: '#fffae6',
    2: '#fff3c2',
    3: '#ffea92',
    4: '#ffe15f',
    5: '#ffd82f',
    6: '#ffcf01',
    7: '#d9b001',
    8: '#b59301',
    9: '#917601',
    10: '#735d00',
  },
  orange: {
    1: '#fef6ec',
    2: '#fee9d1',
    3: '#fcd8ac',
    4: '#fbc685',
    5: '#fab461',
    6: '#f9a43e',
    7: '#d48b35',
    8: '#b1742c',
    9: '#8e5d23',
    10: '#704a1c',
  },
  red: {
    1: '#fbeeed',
    2: '#f6d6d5',
    3: '#efb5b3',
    4: '#e79390',
    5: '#e0726f',
    6: '#d9534f',
    7: '#b84743',
    8: '#9a3b38',
    9: '#7c2f2d',
    10: '#622524',
  },
  green: {
    1: '#eff8ef',
    2: '#d8eed8',
    3: '#b9e0b9',
    4: '#98d298',
    5: '#79c579',
    6: '#5cb85c',
    7: '#4e9c4e',
    8: '#418341',
    9: '#346934',
    10: '#295329',
  },
  aqua: {
    1: '#ecf9ff',
    2: '#d1f1fe',
    3: '#ade6fd',
    4: '#87dafc',
    5: '#63cffc',
    6: '#41c4fb',
    7: '#37a7d5',
    8: '#2e8bb2',
    9: '#25708f',
    10: '#1d5871',
  },
  lime: {
    1: '#f9fcec',
    2: '#f0f8d1',
    3: '#e4f2ad',
    4: '#d7ec87',
    5: '#cbe663',
    6: '#c0e141',
    7: '#a3bf37',
    8: '#88a02e',
    9: '#6d8025',
    10: '#56651d',
  },
  purple: {
    1: '#f4f2fe',
    2: '#e5dffc',
    3: '#d0c5fa',
    4: '#baabf8',
    5: '#a691f6',
    6: '#9279f4',
    7: '#7c67cf',
    8: '#6856ad',
    9: '#53458b',
    10: '#42366e',
  },
  blue: {
    1: '#ecf5fd',
    2: '#d1e6fb',
    3: '#acd2f7',
    4: '#85bdf4',
    5: '#61a9f0',
    6: '#3e96ed',
    7: '#3580c9',
    8: '#2c6ba8',
    9: '#235687',
    10: '#1c446b',
  },
  platinum: {
    1: '#eff2f3',
    2: '#dadfe1',
    3: '#bcc5ca',
    4: '#9daab1',
    5: '#7f9099',
    6: '#637883',
    7: '#54666f',
    8: '#46555d',
    9: '#38444b',
    10: '#2d363b',
  },
  navy: {
    1: '#e7edf6',
    2: '#c6d3e8',
    3: '#9ab1d7',
    4: '#6a8cc4',
    5: '#3d6ab2',
    6: '#1349a1',
    7: '#103e89',
    8: '#0d3472',
    9: '#0b2a5c',
    10: '#092148',
  },
  blue_grey: {
    1: '#eff1f5',
    2: '#d8dde6',
    3: '#b8c2d2',
    4: '#98a6bd',
    5: '#798baa',
    6: '#5b7297',
    7: '#4d6180',
    8: '#41516b',
    9: '#344156',
    10: '#292e37',
  },
  text_light: {
    primary: '#1f1f1f',
    secondary: '#454545',
    tertiary: '#8c8c8c',
    quaternary: '#d9d9d9',
    disabled: '#8c8c8c',
    invert: '#ffffff',
    link: '#3e96ed',
    contrast: '#e72175',
  },
  text_dark: {
    primary: '#f5f5f5',
    secondary: '#d9d9d9',
    tertiary: '#bfbfbf',
    quaternary: '#454545',
    disabled: '#8c8c8c',
    invert: '#ffffff',
    link: '#9279f4',
    contrast: '#ffffff',
  },
};
