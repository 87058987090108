import { HyperionDsCampaignPerformanceTracker } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionDsCampaignPerformanceTracker[] = [];

export const hyperionDsCampaignPerformanceTrackerSlice = createSlice({
  name: SLICE.HYPERION_DS_CAMPAIGN_PERFORMANCE_TRACKER,
  initialState,
  reducers: {
    clearHyperionDsCampaignPerformanceTracker: () => initialState,
    setHyperionDsCampaignPerformanceTracker: (_, action: PayloadAction<HyperionDsCampaignPerformanceTracker[]>) => action.payload,
  },
});

export const { clearHyperionDsCampaignPerformanceTracker, setHyperionDsCampaignPerformanceTracker } =
  hyperionDsCampaignPerformanceTrackerSlice.actions;

export default hyperionDsCampaignPerformanceTrackerSlice.reducer;
