import { TitanEventSource } from '@cognitiv/galaxy-api';

export const titan_event_source_default: TitanEventSource = {
  advertiser_id: 0,
  advertiser_name: '',
  event_source_id: 0,
  event_source_name: '',
  event_source_identifier: '',
  event_source_type_id: 0,
  event_source_type_name: '',
  event_source_name_formatted: '',
  event_source_category_id: null,
  event_source_category_name: '',
  event_source_state_id: 0,
  event_source_state_name: '',
  parent_event_source_id: null,
  parent_event_source_name: '',
  state_id: 0,
  state_name: '',
  properties: [],
  virtual_event_count: 0,
  revision_id: null,
  virtual_event_count_formatted: '',
  property_count: 0,
  property_count_formatted: '',
  altered_property_count_formatted: '',
  uuid: '',
  today_count: 0,
  yesterday_count: 0,
  this_week_count: 0,
  this_month_count: 0,
  all_time_count: 0,
  daily_average_count: 0,
  weekly_average_count: 0,
  monthly_average_count: 0,
  today_count_formatted: '',
  yesterday_count_formatted: '',
  this_week_count_formatted: '',
  this_month_count_formatted: '',
  all_time_count_formatted: '',
  daily_average_count_formatted: '',
  weekly_average_count_formatted: '',
  monthly_average_count_formatted: '',
  note: null,
  created_by_user_id: 0,
  created_date: '',
  created_date_formatted: '',
  display_name: '',
  event_source_data_provider_id: null,
  event_source_data_provider_name: '',
  event_source_data_provider_path_prefix: '',
  event_source_data_provider_root_source_path: '',
  event_source_data_provider_full_source_path: '',
};
