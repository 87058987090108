import { VirgoCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/virgo/operators/slice';

const initialState: VirgoCampaign[] = [];

export const virgoCampaignsSlice = createSlice({
  name: SLICE.VIRGO_CAMPAIGNS,
  initialState,
  reducers: {
    clearVirgoCampaigns: () => initialState,
    setVirgoCampaigns: (_, action: PayloadAction<VirgoCampaign[]>) => action.payload,
  },
});

export const { clearVirgoCampaigns, setVirgoCampaigns } = virgoCampaignsSlice.actions;

export default virgoCampaignsSlice.reducer;
