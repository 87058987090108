import { ORION_SEGMENT_TYPE, ORION_SEGMENT_TYPE_NAME } from 'products/orion/operators/segment-types/enums';
import { OrionSegmentType } from 'products/orion/operators/segment-types/types';

export const orion_segment_types_default: OrionSegmentType[] = [
  {
    segment_type_name: ORION_SEGMENT_TYPE_NAME[ORION_SEGMENT_TYPE.RELEVANCE],
    segment_type_id: ORION_SEGMENT_TYPE.RELEVANCE,
    uuid: 1,
  },
  {
    segment_type_name: ORION_SEGMENT_TYPE_NAME[ORION_SEGMENT_TYPE.CTV],
    segment_type_id: ORION_SEGMENT_TYPE.CTV,
    uuid: 2,
  },
];
